import * as React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './NoEventFilter.styles';
import { Text, View, TouchableOpacity } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { FILTER_BATCH } from '@gf/cross-platform-lib/constants';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const SubText = styled(Text)`
  ${styles.subText};
`;

const TitleText = styled(Text)`
  ${styles.titleText};
`;

const ButtonResetFilters = styled(View)`
  ${styles.buttonResetFilters};
`;

const ButtonText = styled(Text)`
  ${styles.buttonText};
`;

const HorizontalLine = styled(View)`
  ${styles.horizontalLine};
`;

const HorizontalWrapper = styled(View)`
  ${styles.horizontalWrapper};
`;

interface NoEventFilterProps {
  isMobile: boolean;
  handleResetFilters: () => void;
  applyFilter: (type: string, selected: boolean, params: any) => void;
}

export const NoEventFilter = ({ isMobile, handleResetFilters, applyFilter }: NoEventFilterProps) => {
  const handleReset = () => {
    const emptyFilter = {
      activities: [],
      levels: [],
      genders: [],
      homeAway: []
    };

    handleResetFilters();
    applyFilter(FILTER_BATCH, true, emptyFilter);
  };
  return (
    <Wrapper isMobile={isMobile}>
      <Carbonicons color='#000000' name='textLinkAnalysis' size={20} />
      <TitleText>No Events Found</TitleText>
      <SubText>Change filters to see more events if available</SubText>
      <TouchableOpacity onPress={handleReset}>
        <ButtonResetFilters>
          <ButtonText>Reset filters</ButtonText>
        </ButtonResetFilters>
      </TouchableOpacity>
      <HorizontalWrapper isMobile={isMobile}>
        <HorizontalLine isMobile={isMobile} />
      </HorizontalWrapper>
    </Wrapper>
  );
};
