import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  wrapper: css<{ isMobile: boolean }>`
    margin-right: 24px;
    display: flex;
    flex-grow: 1;
    ${({ isMobile }) =>
      isMobile
        ? `
      margin: 0;
      margin-bottom: 8px;
      max-width: 100%;
    `
        : `
      flex: 1;
    `};
  `,
  venueNameTitle: css`
    ${baseStyles.venueNameTitle}
  `,
  eventTitle: css<{ isMobile: boolean }>`
    color: #323232;
    font-family: Rubik-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 8px;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin-top: 0;
    `};
  `,
  subTitle: css`
    ${baseStyles.subTitle}
  `
};
