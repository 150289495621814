import { useQuery } from '@tanstack/react-query';
import { getAllTeams, getTeams } from '../apis/teamApis';
import { buildQueryKey } from '../utils/buildQueryKey';
import { StorableType } from '@gf/cross-platform-lib/utils/config/config';
import { IPagination } from '@gf/cross-platform-lib/interfaces/IPaginationRequest';

export const useGetAllTeams = (
  organizationId: string,
  pagination: IPagination = {
    page: 0,
    size: 50
  }
) => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.TeamSearchResults, organizationId]),
    queryFn: () => getAllTeams(organizationId, pagination),
    staleTime: 1000 * 60,
    enabled: !!organizationId
  }).data?.data?.content;
};

export const useGetTeams = (teamIds: string[]) => {
  return useQuery({
    queryFn: () => getTeams(teamIds),
    queryKey: buildQueryKey([StorableType.TeamSearchResults, teamIds]),
    staleTime: 1000 * 60,
    enabled: !!teamIds
  }).data;
};
