import React, { useEffect, useRef } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { useMediaQuery, useNavigate, useTracking } from '@gf/cross-platform-lib/hooks';
import { ScrollView, TouchableWithoutFeedback, View, Text, TouchableOpacity, Pressable } from 'react-native';
import { GFImage, HorizontalRow, StyledText } from '@gf/cross-platform-lib/components';
import { Fan } from '@gf/cross-platform-lib/models';
import { SCHOOL_DISTRICT_LIST } from '@gf/cross-platform-lib/providers';
import { ToggleFavorite } from '../ToggleFavorite';
import { SchoolDistrictInfo, SchoolType } from '../../../..';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { GetCurrentEnvironmentName, getSchoolDistrictTypeName } from '@gf/cross-platform-lib/utils';
import pluralize from 'pluralize';
import ListHeader from '@gf/cross-platform-lib/providers/DrawerProvider/DrawerComponents/ListHeader/ListHeader';

interface SchoolDistrictListProps {
  schoolName: string;
  schoolId: string;
  districtSchools: SchoolDistrictInfo[];
}

export const SchoolDistrictList = ({ schoolName, schoolId, districtSchools }: SchoolDistrictListProps) => {
  const fan = Fan.getInstance();
  const { isNativeApp, isMobile } = useMediaQuery();
  const [favSchools, setFavSchool] = React.useState<string[]>(fan.favorSchoolIds);
  const { trackViewDistrictSchoolList, trackUnFavoriteEvent, trackFavoriteEvent } = useTracking();
  const trackingRef = useRef(false);
  const schoolTypeSchools = districtSchools.reduce(
    (schoolsTypeToSchools, schoolData) => {
      if (favSchools.includes(schoolData.huddle_id)) {
        schoolsTypeToSchools['favorite school'].push(schoolData);
      } else {
        switch (schoolData.school_type.toLowerCase()) {
          case 'elementary school':
            schoolsTypeToSchools['elementary school'].push(schoolData);
            break;
          case 'middle school':
            schoolsTypeToSchools['middle school'].push(schoolData);
            break;
          case 'high school':
            schoolsTypeToSchools['high school'].push(schoolData);
            break;
          default:
            schoolsTypeToSchools['other'].push(schoolData);
            break;
        }
      }
      return schoolsTypeToSchools;
    },
    {
      'favorite school': [] as SchoolDistrictInfo[],
      'elementary school': [] as SchoolDistrictInfo[],
      'middle school': [] as SchoolDistrictInfo[],
      'high school': [] as SchoolDistrictInfo[],
      other: [] as SchoolDistrictInfo[]
    }
  );
  const schoolTypesToSchools = new Map<string, SchoolDistrictInfo[]>();
  Object.entries(schoolTypeSchools).forEach(([schoolType, schools]) => {
    if (schools.length > 0) {
      schoolTypesToSchools.set(schoolType, schools);
    }
  });

  const navigate = useNavigate();

  const getNumberOfEventsText = (today: number, upcoming: number): string => {
    if (today + upcoming < 1) return '';
    if (today && upcoming === 0) return today + pluralize('event', today) + ' today';
    if (today < 1 && upcoming > 0) return upcoming + ' ' + pluralize('event', upcoming);
    if (today > 0 && upcoming > 0) return upcoming + ' ' + pluralize('event', upcoming) + ' • ' + today + ' today';
    return '';
  };

  const handleFavoriteToggle = (id: string, name: string, gofanSchoolType: string) => {
    fan.toggleFavoriteSchool(id, name);
    setFavSchool(prevState => {
      const toggledFavSchools = prevState.includes(id) ? prevState.filter(favId => favId !== id) : [...prevState, id];
      return toggledFavSchools;
    });

    const isFavorite = favSchools.includes(id);
    isFavorite
      ? trackUnFavoriteEvent(GOFAN_APP_PAGES.school.path, {
          orgId: id || '',
          orgType: gofanSchoolType || ''
        })
      : trackFavoriteEvent(GOFAN_APP_PAGES.school.path, {
          orgId: id || '',
          orgType: gofanSchoolType || ''
        });
  };

  useEffect(() => {
    if (!trackingRef.current && schoolTypesToSchools && districtSchools.length > 0) {
      trackingRef.current = true;
      trackViewDistrictSchoolList({
        home_org: schoolId,
        number_of_schools: districtSchools.length,
        type_of_schools:
          getSchoolDistrictTypeName(Array.from(schoolTypesToSchools.keys()).map(schoolType => schoolType.toString())) ||
          ''
      });
    }
  }, [schoolTypesToSchools, trackingRef]);

  return (
    <TouchableWithoutFeedback>
      <Container isNativeApp={isNativeApp} isMobile={isMobile}>
        <ListHeader headerTitle={`${schoolName} District Schools`} drawerId={SCHOOL_DISTRICT_LIST} />
        <ScrollView showsHorizontalScrollIndicator={false} bounces={false}>
          {schoolTypesToSchools &&
            Array.from(schoolTypesToSchools.entries())
              .sort((a, b) => {
                const schoolTypeA = a[0];
                const schoolTypeB = b[0];
                return parseInt(SchoolType[schoolTypeA as any]) - parseInt(SchoolType[schoolTypeB as any]);
              })
              .map(schoolTypeToSchools => {
                const schoolType = schoolTypeToSchools[0];
                const schools = schoolTypeToSchools[1];
                return (
                  <View key={schoolType}>
                    <SchoolTypeHeader>
                      <Pressable>
                        <StyledText typeToken='label03'>
                          {schoolType.charAt(0).toUpperCase() + schoolType.slice(1) + 's'}
                        </StyledText>
                      </Pressable>
                    </SchoolTypeHeader>
                    {schools.map(school => {
                      const env =
                        GetCurrentEnvironmentName() === 'production' ? GetCurrentEnvironmentName() : 'development';
                      const logo = school.logo
                        ? `https://${env}-gofan-assets.s3.amazonaws.com/uploads/school/logo/` +
                          school.huddle_id +
                          '/' +
                          school.logo.replaceAll(' ', '+')
                        : 'gofan-icon.png';
                      const id = school.huddle_id;
                      return (
                        <View key={id}>
                          <SchoolInfoContainer>
                            <SchoolInfo
                              onPress={() => {
                                navigate(
                                  `${GOFAN_APP_PAGES.school.getPath(school.huddle_id)}?redirectFromSchoolDistrict=true`
                                );
                              }}
                            >
                              <GFImage src={logo} height={32} width={32} />
                              <SchoolText>
                                <SchoolNameText>{school.name}</SchoolNameText>
                                <SchoolEventsInfoText>
                                  {getNumberOfEventsText(school.num_of_events_today, school.num_of_events_upcoming)}
                                </SchoolEventsInfoText>
                              </SchoolText>
                            </SchoolInfo>
                            <ToggleFavorite
                              isFavorite={favSchools?.includes(id)}
                              toggleFavorite={() => handleFavoriteToggle(id, school.name, school.school_type)}
                            />
                          </SchoolInfoContainer>
                          <HorizontalRow marginBottom={0} marginTop={0} paddingVertical={0} />
                        </View>
                      );
                    })}
                  </View>
                );
              })}
        </ScrollView>
      </Container>
    </TouchableWithoutFeedback>
  );
};
const Container = styled(View)`
  ${styles.container}
`;
const SchoolTypeHeader = styled(View)`
  ${styles.schoolTypeHeader}
`;
const SchoolNameText = styled(Text)`
  ${styles.schoolNameText}
`;
const SchoolEventsInfoText = styled(Text)`
  ${styles.schoolEventsInfoText}
`;
const SchoolInfoContainer = styled(Pressable)`
  ${styles.schoolInfoContainer}
`;
const SchoolInfo = styled(TouchableOpacity)`
  ${styles.schoolInfo}
`;
const SchoolText = styled(View)`
  ${styles.schoolText}
`;

export default SchoolDistrictList;
