import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './ActivityItem.styles';
import { activityIcons } from '@gf/cross-platform-lib/constants/';
import { GFImage } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

export interface ActivityProps {
  name: string;
  hidePaddingLeft: boolean;
  isMobile: boolean;
}

export const ActivityItem = ({ name, hidePaddingLeft, isMobile }: ActivityProps) => {
  const activityIconSrc =
    // @ts-ignore
    activityIcons[name.replace(/ /g, '').toLowerCase()] || activityIcons.unlabeled;

  return (
    <Wrapper hidePaddingLeft={hidePaddingLeft} isMobile={isMobile}>
      <GFImage width={32} height={32} name={activityIconSrc} />
    </Wrapper>
  );
};
