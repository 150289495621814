import styled from '@gf/cross-platform-lib/styled-components';
import React from 'react';
import { Carbonicons } from '@gf/cross-platform-lib/components';
import { Text, TouchableOpacity, Linking } from 'react-native';
import { styles } from './TeamSitePromotion.styles';
import { PromoLocation, useCustomTracking } from '@gf/cross-platform-lib/hooks/tracking/useCustomTracking';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { formatHexString } from '@gf/cross-platform-lib/utils';

const Container = styled(TouchableOpacity)`
  ${styles.container}
`;
const SiteUrl = styled(Text)`
  ${styles.siteUrl}
`;

interface TeamSitePromotionProps {
  schoolId: string;
  displayName: string;
  src: string;
  enableOutline?: boolean;
  enableIcon?: boolean;
  hexColor?: string;
  enableBoldText?: boolean;
  componentLocation: PromoLocation;
  fullWidth?: boolean;
}

export const TeamSitePromotion = ({
  schoolId,
  displayName,
  src,
  enableOutline = true,
  enableIcon = true,
  hexColor = '000000',
  enableBoldText = false,
  componentLocation,
  fullWidth = false
}: TeamSitePromotionProps) => {
  const { trackClickTeamSitePromo } = useCustomTracking();
  const formattedHex = formatHexString(hexColor);
  const handlePress = () => {
    trackClickTeamSitePromo({
      home_org: schoolId,
      page: componentLocation,
      url: src
    });
    Linking.openURL(src).catch(err => {
      recordError(err, {
        originatingFunction: 'TeamSitePromotion-handlePress',
        customMessage: 'Failed to open external URL.',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Navigation,
        data: { src }
      });
    });
  };

  return (
    <Container
      testID='promo-site-button'
      onPress={handlePress}
      enableOutline={enableOutline}
      color={formattedHex}
      fullWidth={fullWidth}
    >
      <SiteUrl enableBoldText={enableBoldText} color={formattedHex}>
        {displayName}
      </SiteUrl>
      {enableIcon && <Carbonicons name='launch' size={16} color={formattedHex} />}
    </Container>
  );
};
