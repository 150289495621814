import { useGetEventCategoriesQuery, useGetSeasonCategoriesQuery } from '../../AcquisitionV2';
import uniq from 'lodash/uniq';

const defaultState = {
  searchOptions: [],
  activities: [],
  levels: [],
  genders: [],
  playOff: false,
  schools: []
};

export const useGetAllEventCategories = (schoolId = '') => {
  const {
    data: eventCategoriesData,
    isLoading: loadingCategories,
    refetch: refetchGetEventCategories
  } = useGetEventCategoriesQuery(schoolId);
  const {
    data: seasonCategoriesData,
    isLoading: loadingSeasonCategories,
    refetch: refetchGetSeasonCategories
  } = useGetSeasonCategoriesQuery(schoolId);

  const eventCategories = eventCategoriesData?.data || defaultState;
  const seasonCategories = seasonCategoriesData?.data || defaultState;

  return {
    refetch: async () => {
      return await Promise.all([refetchGetEventCategories(), refetchGetSeasonCategories()]);
    },
    listCategories: {
      searchOptions: uniq(
        [...eventCategories?.searchOptions, ...seasonCategories?.searchOptions].filter(location => location)
      ),
      activities: uniq([...eventCategories?.activities, ...seasonCategories?.activities]),
      genders: uniq([...eventCategories?.genders, ...seasonCategories?.genders]),
      playOff: eventCategories?.playOff || seasonCategories?.playOff,
      schools: uniq([...eventCategories?.schools, ...seasonCategories?.schools]),
      levels: uniq([...eventCategories?.levels, ...seasonCategories?.levels])
    },
    isLoading: loadingCategories || loadingSeasonCategories
  };
};
