import * as React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';

// @ts-ignore
import styles from './SchoolFooter.styles';
import { isEmpty, schoolPage, testProperties } from '@gf/cross-platform-lib/utils';
import { Carbonicons, ExternalLink, StyledText } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { TextTokenType } from '@gf/cross-platform-lib/styled-system';

const FooterWrapper = styled(View)`
  ${styles.footerWrapper};
`;

const AboutDetailsContainer = styled(View)`
  ${styles.aboutDetailsContainer};
`;

const LocationSection = styled(View)`
  ${styles.locationSection};
`;

const SocialSection = styled(View)`
  ${styles.socialSection};
`;

const LeftMarginWrapper = styled(View)`
  ${styles.leftMarginWrapper};
`;

interface SchoolFooterProps {
  schoolName: string;
  address?: string;
  location: string;
  twitterUrl?: string;
  facebookUrl?: string;
}

type AboutTextProps = { text: string | null | undefined; typeToken: TextTokenType };
const AboutText = ({ text, typeToken }: AboutTextProps) => {
  return text !== null && text !== '' ? (
    <StyledText
      color='#323232'
      typeToken={typeToken}
      textAlign={'left'}
      style={{ letterSpacing: 0.16, marginBottom: 4 }}
    >
      {text}
    </StyledText>
  ) : null;
};

export const SchoolFooter = ({ schoolName, address, location, twitterUrl, facebookUrl }: SchoolFooterProps) => (
  <FooterWrapper {...testProperties(schoolPage.aboutSection)}>
    <LeftMarginWrapper>
      <StyledText
        color='#323232'
        typeToken='heading02'
        textAlign={'left'}
        style={{ letterSpacing: 0.16, marginBottom: 4, fontSize: 20 }}
      >
        About
      </StyledText>
    </LeftMarginWrapper>
    <AboutDetailsContainer>
      <LocationSection>
        {!isEmpty(schoolName) ? <AboutText text={schoolName} typeToken='heading01' /> : null}
        {!isEmpty(address) ? <AboutText text={address} typeToken='body01' /> : null}
        {!isEmpty(location) ? <AboutText text={location} typeToken='body01' /> : null}
      </LocationSection>
      {(!isEmpty(facebookUrl) || !isEmpty(twitterUrl)) && (
        <SocialSection>
          <StyledText typeToken='body01' color='textPrimary' textAlign='center' style={{ fontSize: 12 }}>
            Follow
          </StyledText>
          {facebookUrl && (
            <ExternalLink href={facebookUrl || ''} style={{ marginLeft: 18 }}>
              <Carbonicons name='facebook' size={18} color='black' />
            </ExternalLink>
          )}
          {twitterUrl && (
            <ExternalLink href={twitterUrl || ''} style={{ marginLeft: 18 }}>
              <Carbonicons name='logoTwitter' size={18} color='black' />
            </ExternalLink>
          )}
        </SocialSection>
      )}
    </AboutDetailsContainer>
  </FooterWrapper>
);
