import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { View, Text, TouchableOpacity } from 'react-native';
import { ToggleFavorite } from '@gf/cross-platform-lib/modules/school/components/ToggleFavorite/ToggleFavorite';
import { ChevronRight } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { TeamListItemProps } from '../TeamListItem';
import { ATHLETIC_TEAMS_LIST, useDrawerContext } from '@gf/cross-platform-lib/providers';
import { FILTER_BATCH } from '@gf/cross-platform-lib/constants';
import { levelMapper, testProperties } from '@gf/cross-platform-lib/utils';

export const TeamListItem = ({
  gender,
  levelName,
  seasonName,
  isFavorite,
  toggleFavorite,
  activityName,
  applyFilter,
  appliedFilter
}: TeamListItemProps) => {
  const { closeDrawer } = useDrawerContext();
  const onTeamPress = () => {
    const updatedFilter = {
      ...appliedFilter,
      activities: [activityName],
      levels: [levelMapper(levelName)],
      genders: [gender]
    };

    applyFilter(FILTER_BATCH, true, updatedFilter);
    closeDrawer(ATHLETIC_TEAMS_LIST);
  };

  return (
    <Container {...testProperties('team-list-item-regular')}>
      <LeftContainer>
        <ToggleFavorite isFavorite={isFavorite} toggleFavorite={toggleFavorite} iconHeight='12px' iconWidth='12px' />
        <TeamName numberOfLines={1} {...testProperties('teams-list-team')}>{`${gender} ${levelName}`}</TeamName>
      </LeftContainer>
      <RightContainer>
        <SeasonName {...testProperties('teams-list-season')}>{seasonName}</SeasonName>
        <TouchableOpacity onPress={onTeamPress} {...testProperties('teams-list-chevron')}>
          <ChevronRight height={32} width={32} fill='#191919'></ChevronRight>
        </TouchableOpacity>
      </RightContainer>
    </Container>
  );
};
const Container = styled(View)`
  ${styles.container}
`;
const LeftContainer = styled(View)`
  ${styles.leftContainer}
`;
const RightContainer = styled(View)`
  ${styles.rightContainer}
`;
const TeamName = styled(Text)`
  ${styles.teamName}
`;
const SeasonName = styled(Text)`
  ${styles.seasonName}
`;

export default TeamListItem;
