import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { EventSeason, EventSeasonCombined, School, IEventSponsorship } from '@gf/cross-platform-lib/interfaces';

import { EventListSection } from '../EventListSection';
import { NoEventFound } from '../NoEventFound';
import { styles } from './EventList.styles';
import { useSchoolBorderColor } from '@gf/cross-platform-lib/hooks';
import { getQueryStringFilter, mapToQueryStringFilter, SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
import { View } from 'react-native';
import { schoolPage, testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const HorizontalDivider = styled(View)`
  ${styles.horizontalDivider};
`;

interface EventListProps {
  displaySchoolSearch?: boolean;
  schoolDetail: School;
  isMobile: boolean;
  eventLoading: boolean;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  resetFilters: () => void;
  refreshEvents?: () => void;
  totalItems: number;
  appliedFilter: SelectedFilter;
  todayEvents: EventSeasonCombined[];
  upComingEvents: EventSeasonCombined[];
  sponsor?: IEventSponsorship;
}

export const EventList = ({
  displaySchoolSearch,
  eventLoading,
  isMobile,
  refreshEvents,
  applyFilter,
  resetFilters,
  schoolDetail,
  appliedFilter,
  todayEvents,
  totalItems,
  upComingEvents,
  sponsor
}: EventListProps) => {
  const isApplyFilter = !!getQueryStringFilter(mapToQueryStringFilter(appliedFilter));
  const schoolBorderColor = useSchoolBorderColor(schoolDetail?.primaryColor);

  const handleRefreshEvents = () => {
    refreshEvents && refreshEvents();
  };

  const NoEventFoundView = () => {
    if (eventLoading) {
      return <View style={{ height: 200 }} />;
    }
    return (
      <>
        <NoEventFound key='lblNoEvent' isMobile={isMobile} handleRefreshEvents={handleRefreshEvents} />
        <HorizontalDivider isMobile={isMobile} />
      </>
    );
  };

  const todayEventsCount = todayEvents.length;
  const upcomingEventsCount = todayEventsCount > 0 ? totalItems - todayEventsCount : totalItems;

  return (
    <Wrapper {...testProperties(schoolPage.eventList)} displaySchoolSearch={displaySchoolSearch}>
      {totalItems === 0 && !isApplyFilter && NoEventFoundView()}
      {(todayEventsCount > 0 || isApplyFilter) && (
        <EventListSection
          count={todayEventsCount}
          title="Today's events"
          events={todayEvents as EventSeason[]}
          schoolDetail={schoolDetail}
          schoolBorderColor={schoolBorderColor}
          textId={'lblTodaysEvents'}
          applyFilter={applyFilter}
          handleResetFilters={resetFilters}
          isMobile={isMobile}
          refreshEvents={handleRefreshEvents}
          isApplyFilter={isApplyFilter}
          testId={schoolPage.eventListToday}
          sponsor={sponsor}
          todayEventsCount={todayEventsCount}
          upcomingEventsCount={upcomingEventsCount}
          appliedFilter={appliedFilter}
        />
      )}
      {(upcomingEventsCount > 0 || isApplyFilter) && (
        <EventListSection
          count={upcomingEventsCount}
          title='Upcoming events'
          events={upComingEvents}
          schoolDetail={schoolDetail}
          schoolBorderColor={schoolBorderColor}
          textId={'lblUpcomingEvents'}
          applyFilter={applyFilter}
          handleResetFilters={resetFilters}
          isMobile={isMobile}
          refreshEvents={handleRefreshEvents}
          isApplyFilter={isApplyFilter}
          testId={schoolPage.eventListUpcoming}
          sponsor={sponsor}
          todayEventsCount={todayEventsCount}
          upcomingEventsCount={upcomingEventsCount}
          appliedFilter={appliedFilter}
        />
      )}
    </Wrapper>
  );
};
