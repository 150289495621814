import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css<{ isMobile: boolean }>`
    display: flex;
    transition: all 0.35s;
    width: ${({ isMobile }) => (isMobile ? '100%' : '360px')};
    padding: 32px;
    padding-bottom: 0px;
    height: ${({ isMobile }) => (isMobile ? '100%' : 'calc(100%)')};
  `,
  categoryContainer: css<{ isNativeApp: boolean; isMobile: boolean }>`
    margin-bottom: 24px;
  `
};
