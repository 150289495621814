import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css`
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    flex-direction: column;
  `,
  venueNameTitle: css`
    color: #161616;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    max-height: 100%;
  `,
  eventTitle: css`
    color: #323232;
    font-family: Rubik-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    max-height: 100%;
  `,
  subTitle: css`
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    flex-shrink: 1;
    max-height: 100%;
  `
};
