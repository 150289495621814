import { css } from '@gf/cross-platform-lib/styled-components';
import { styles as baseStyles } from './base.styles';

export const styles = {
  wrapper: css<{ backgroundColor: string; isMobile: boolean }>`
    ${baseStyles.wrapper}
  `,
  contentWrapper: css`
    ${baseStyles.contentWrapper}
    max-width: 960px;
    width: 100%;
    padding: 16px 16px;
  `,
  schoolProfile: css`
    ${baseStyles.schoolProfile}
  `,
  schoolDetailWrapper: css`
    ${baseStyles.schoolDetailWrapper}
    width: calc(100% - 32px);
  `,
  logo: css<{ isMobile: boolean }>`
    ${baseStyles.logo}
    height: 120px;
    width: 120px;
    padding-left: 0px;
    ${({ isMobile }) =>
      isMobile &&
      `
        height: 72px;
        width: 72px;
    `};
  `,
  schoolDetailName: css<{ textColor: string; isMobile: boolean }>`
    ${baseStyles.schoolDetailName}
    padding: 0 16px;
    text-align: left;
    letter-spacing: 0.41px;
    font-family: Rubik-SemiBold;
    font-size: 32px;
    font-weight: 600;
    line-height: -1.3;
    width: auto;
    ${({ isMobile }) =>
      isMobile &&
      `
        font-size: 20px;
        font-family: Rubik-Regular;
        font-weight: normal;
        line-height: 26px;
    `};
  `,
  actionButtonsWrapper: css`
    ${baseStyles.actionButtonsWrapper}
  `,
  schoolDetailNameWrapper: css`
    ${baseStyles.schoolDetailNameWrapper}
  `,
  viewAllSchoolsButton: css`
    ${baseStyles.viewAllSchoolsButton}
  `,
  sitePromoContainer: css<{ color: string; isMobile: boolean }>`
    ${baseStyles.sitePromoContainer}
    justify-content: flex-end;
    max-width: ${({ isMobile }) => (isMobile ? '100%' : '220px')};
    align-items: center;
    margin-right: ${({ isMobile }) => (isMobile ? '0' : '16px')};
  `
};
