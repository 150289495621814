import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  homeAwayTag: css<{ backgroundColor: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 18px;
    border-radius: 12px;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#dbdbdb')};
    margin-left: 8px;
  `,
  homeAwayTitle: css<{ color: string }>`
    color: ${({ color }) => (color === '#323232' ? 'white' : 'black')};
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
  `,
  emptyTag: css`
    width: 60px;
    height: 18px;
    margin-left: 8px;
  `
};
