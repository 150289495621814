import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

type ContainerType = { selected: any; disabledHover: any };

export const styles = {
  container: css<{
    selected: boolean;
    disabledHover: boolean;
    shouldDisplayBorder: boolean;
    primaryColor: string;
  }>`
    ${baseStyles.container};
    @media (hover: hover) and (pointer: fine) {
      :hover {
        background-color: ${({ selected }: ContainerType) => (selected ? '#323232' : '#d3d3d3')};
        cursor: pointer;
      }

      :active {
        background-color: #f4f4f4;
        border-color: transparent;
        cursor: pointer;
      }
    }
  `,
  icon: css<{ selected: boolean }>`
    ${baseStyles.icon}
  `,
  expandIcon: css`
    ${baseStyles.expandIcon}
  `,
  labelContainer: css<{ selected: boolean }>`
    ${baseStyles.labelContainer}
  `
};
