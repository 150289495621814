import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css`
    margin-top: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  titleText: css`
    margin-top: 8px;
    color: #323232;
    font-family: Rubik-SemiBold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.16px;
    line-height: 18px;
    text-align: center;
  `,
  subText: css`
    margin-top: 8px;
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 18px;
    text-align: center;
    max-height: 100%;
  `,
  buttonResetFilters: css`
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #323232;
    border: 0;
    border-radius: 4px;
    padding: 10px 40px;
    margin-top: 24px;
  `,
  buttonText: css`
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    color: #ffffff;
  `,
  horizontalWrapper: css<{ isMobile: boolean }>`
    width: 100%;
    ${({ isMobile }) =>
      isMobile &&
      `
      padding-left: 32px;
      padding-right: 32px;
    `};
  `,
  horizontalLine: css<{ isMobile: boolean }>`
    border: 1px solid #d3d3d3;
    height: ${({ isMobile }) => (isMobile ? '1px' : '2px')};
    margin-top: ${({ isMobile }) => (isMobile ? '24px' : '32px')};
  `
};
