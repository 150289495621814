import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
  `,
  dateSection: css`
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 4px;
    padding-bottom: 8px;
  `,
  rightArrowSection: css`
    align-self: center;
    display: flex;
    align-items: center;
  `,
  featuredSection: css`
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  mobileFooter: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    align-items: center;
  `,
  mobileLeftSection: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  `,
  mobileTag: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-end;
  `,
  iconWrapper: css`
    margin-right: 4px;
    height: 20px;
  `,
  moreWrapper: css`
    display: flex;
    flex-direction: row;
    color: #282828;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    align-items: center;
    border: 0;
    background-color: transparent;
  `,
  mobileRightSection: css<{ isMobilePass: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  mobileInfo: css`
    display: flex;
    flex-direction: row;
    margin-top: 0;
    justify-content: space-between;
  `,
  mobileHeader: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  `
};
