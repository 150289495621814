import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { View, Text } from 'react-native';
import { GFImage } from '@gf/cross-platform-lib/components';
import isEmpty from 'lodash/isEmpty';

interface CategoryHeaderProps {
  activityName?: string;
  activityIconName: string;
}

export const CategoryHeader = ({ activityName, activityIconName }: CategoryHeaderProps) => {
  return (
    <Container>
      {!isEmpty(activityIconName) && (
        <ImageWrapper>
          <GFImage name={activityIconName} height={21} width={21} />
        </ImageWrapper>
      )}
      <ActivityText>{activityName}</ActivityText>
    </Container>
  );
};
const Container = styled(View)`
  ${styles.container}
`;
const ImageWrapper = styled(View)`
  ${styles.imageWrapper}
`;
const ActivityText = styled(Text)`
  ${styles.activityText}
`;

export default CategoryHeader;
