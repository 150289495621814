import React, { useState } from 'react';
import { EventSeason } from '@gf/cross-platform-lib/interfaces';

import { InfoSection } from '../InfoSection';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './FundraiserItem.styles';
import {
  eventCardComponent,
  getCityState,
  getDateRange,
  getLevelGenderTitle,
  getVenueCityState,
  invertColor,
  isAggregatorSchool,
  isSameDayEvent,
  schoolPage,
  testProperties
} from '@gf/cross-platform-lib/utils';
import { BuyEventTicketBtn } from '../BuyEventTicketBtn';
import { ActivityItem } from '../ActivityItem';
import { Featured } from '../Featured';
import { FundraiserProgressBar } from '@gf/cross-platform-lib/modules/event';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { Text, TouchableOpacity, View } from 'react-native';
import { FundraiserFooter } from '../FundraiserFooter';
import { useDonationsRaised } from '@gf/cross-platform-lib/hooks/useDonationsRaised';
import { DEFAULT_FUNDRAISER_PROGRESS_BAR_COLOR } from '@gf/cross-platform-lib/constants';
import { Event, ProductType, IEventSponsorship } from '@gf/cross-platform-lib/interfaces';
import { useGetEventQuery } from '@gf/cross-platform-lib/modules/AcquisitionV2/queries/eventQueries';

const ContentWrapper = styled(View)`
  ${styles.contentWrapper};
`;

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const DateSection = styled(View)`
  ${styles.dateSection};
`;

const RightArrowSection = styled(View)`
  ${styles.rightArrowSection};
`;

const FeaturedSection = styled(View)`
  ${styles.featuredSection};
`;

const MobileFooter = styled(View)`
  ${styles.mobileFooter};
`;

const MobileRightSection = styled(View)`
  ${styles.mobileRightSection};
`;

const FundraiserTag = styled(View)`
  ${styles.fundraiserTag};
`;

const FundraiserText = styled(Text)`
  ${styles.fundraiserText};
`;

const MobileTag = styled(View)`
  ${styles.mobileTag};
`;

const IconWrapper = styled(View)`
  ${styles.iconWrapper};
`;

const MoreWrapper = styled(View)`
  ${styles.moreWrapper};
`;

const MobileInfo = styled(View)`
  ${styles.mobileInfo};
`;

const MobileHeader = styled(View)`
  ${styles.mobileHeader};
`;

const ProgressWrapper = styled(View)`
  ${styles.progressWrapper};
`;

export interface FundraiserItemProps {
  isMobile: boolean;
  fundraiserItem: EventSeason;
  primaryColor?: string;
  schoolId: string;
  industryCode: string;
  isFeatured: boolean;
  eventCount?: number;
  donationGoal?: number;
  barColor?: string;
  sponsor?: IEventSponsorship;
}

export const FundraiserItem = ({
  fundraiserItem,
  primaryColor,
  isMobile,
  schoolId,
  industryCode,
  isFeatured,
  eventCount,
  donationGoal,
  barColor,
  sponsor
}: FundraiserItemProps) => {
  const [isShowFooter, setIsShowFooter] = useState(!isMobile);
  const isSeason = fundraiserItem.isSeason;
  const textColor = primaryColor?.toUpperCase() === 'TRANSPARENT' ? '#000000' : invertColor(primaryColor || '', true);
  const isSameDay = isSameDayEvent(fundraiserItem.startDateTime, fundraiserItem.endDateTime, fundraiserItem.timeZone);
  const eventDateRange = !isSameDay ? getDateRange(fundraiserItem) : null;
  const venueCityState = fundraiserItem.venue ? getVenueCityState(fundraiserItem.venue) : '';
  const isAggregatorOrg = isAggregatorSchool(industryCode);
  const venueTitleOnState = isAggregatorOrg ? fundraiserItem?.venue?.name || '' : getCityState(fundraiserItem.venue);
  const mobileVenueTitle = isAggregatorOrg ? venueTitleOnState : venueCityState;

  const subTitle = getLevelGenderTitle(
    fundraiserItem.seasonTypeName ?? '',
    fundraiserItem.levels ?? [],
    fundraiserItem.genders,
    fundraiserItem.optionalTypeDescription
  );
  const eventLink = `/event/${fundraiserItem.id}?schoolId=${schoolId}`;
  const eventResponse = useGetEventQuery(fundraiserItem.id);
  const event = eventResponse.data?.data;
  const { donationsRaisedTotal } = useDonationsRaised(event!);

  const isDonationOngoing = fundraiserItem?.ticketTypes.every(
    ticket => ticket.productType === ProductType.DONATION_ONGOING
  );

  return (
    <ContentWrapper isMobile={isMobile} primaryColor={primaryColor ?? '#000000'}>
      <Wrapper isMobile={isMobile} {...testProperties(schoolPage.fundraiserCard)}>
        {isMobile ? (
          <>
            <MobileInfo>
              <MobileRightSection>
                <MobileHeader>
                  <MobileTag>
                    <ActivityItem
                      name={fundraiserItem.activity?.name ?? ''}
                      hidePaddingLeft={true}
                      isMobile={isMobile}
                    />
                  </MobileTag>
                  <MobileTag>
                    {isFeatured && (
                      <FeaturedSection>
                        <Featured primaryColor={primaryColor} textColor={textColor} isMobile={isMobile} />
                      </FeaturedSection>
                    )}
                    <FundraiserTag
                      {...testProperties(schoolPage.eventTag)}
                      primaryColor={primaryColor}
                      isMobile={isMobile}
                    >
                      <FundraiserText textColor={textColor}>Fundraiser</FundraiserText>
                    </FundraiserTag>
                  </MobileTag>
                </MobileHeader>
                <InfoSection
                  activityLabel={fundraiserItem.activity?.name ?? ''}
                  eventTypeName={fundraiserItem.seasonTypeName ?? ''}
                  name={fundraiserItem.title ?? ''}
                  subTitle={subTitle}
                  startDateTime={fundraiserItem.startDateTime!}
                  endDateTime={fundraiserItem.endDateTime!}
                  timeZone={fundraiserItem.timeZone ?? ''}
                  isSeason
                  isFundraiser={true}
                  isDonationOnGoing={isDonationOngoing}
                  isMobile={isMobile}
                  eventCount={fundraiserItem?.eventIds?.length || 0}
                  sponsor={sponsor}
                />
              </MobileRightSection>
            </MobileInfo>
            {fundraiserItem.goal && (
              <ProgressWrapper>
                <FundraiserProgressBar
                  donationGoal={donationGoal}
                  donationsRaised={donationsRaisedTotal}
                  barColor={barColor || DEFAULT_FUNDRAISER_PROGRESS_BAR_COLOR}
                  isMobile={isMobile}
                />
              </ProgressWrapper>
            )}
            <MobileFooter>
              <TouchableOpacity
                onPress={() => {
                  setIsShowFooter(!isShowFooter);
                }}
              >
                <MoreWrapper {...testProperties(eventCardComponent.expandButton)}>
                  <IconWrapper>
                    <Carbonicons color='#282828' name={isShowFooter ? 'chevron-up' : 'chevron-down'} size={20} />
                  </IconWrapper>
                  <Text style={{ fontSize: 14, maxWidth: 100 }}>{isShowFooter ? 'less' : 'more'}</Text>
                </MoreWrapper>
              </TouchableOpacity>
              <BuyEventTicketBtn
                isFundraiser={true}
                isSeason={isSeason}
                isMobilePass={false}
                link={eventLink}
                event={fundraiserItem}
              />
            </MobileFooter>
          </>
        ) : (
          <>
            {isFeatured && (
              <FeaturedSection>
                <Featured primaryColor={primaryColor} textColor={textColor} isMobile={isMobile} />
              </FeaturedSection>
            )}
            <DateSection isMobile={isMobile}>
              <FundraiserTag {...testProperties(schoolPage.eventTag)} primaryColor={primaryColor}>
                <FundraiserText textColor={textColor}>Fundraiser</FundraiserText>
              </FundraiserTag>
              <ActivityItem name={fundraiserItem.activity?.name ?? ''} hidePaddingLeft={false} isMobile={isMobile} />
            </DateSection>
            <InfoSection
              activityLabel={fundraiserItem.activity?.name ?? ''}
              eventTypeName={fundraiserItem.seasonTypeName ?? ''}
              name={fundraiserItem.title ?? ''}
              subTitle={subTitle}
              startDateTime={fundraiserItem.startDateTime}
              endDateTime={fundraiserItem.endDateTime}
              timeZone={fundraiserItem.timeZone ?? ''}
              isSeason
              isFundraiser={true}
              isMobile={isMobile}
              eventCount={eventCount}
              donationGoal={donationGoal}
              donationsRaised={donationsRaisedTotal}
              barColor={barColor || DEFAULT_FUNDRAISER_PROGRESS_BAR_COLOR}
              isDonationOnGoing={isDonationOngoing}
              sponsor={sponsor}
            />
            <RightArrowSection>
              <BuyEventTicketBtn
                isFundraiser={true}
                isSeason={isSeason}
                isMobilePass={false}
                link={eventLink}
                event={fundraiserItem}
              />
            </RightArrowSection>
          </>
        )}
      </Wrapper>
      {isShowFooter && (
        <FundraiserFooter
          isMobile={isMobile}
          isFeatured={isFeatured}
          dateRange={eventDateRange}
          venueTitle={!isMobile ? venueCityState : mobileVenueTitle}
        />
      )}
    </ContentWrapper>
  );
};
