import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ hidePaddingLeft: boolean; isMobile: boolean }>`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    > img {
      max-height: 32px;
      width: 32px !important;

      ${({ isMobile }) =>
        isMobile &&
        `
        height: 32px;
        object-fit: contain;
      `};
    }
    margin: ${({ isMobile, hidePaddingLeft }) => {
      if (isMobile) {
        return hidePaddingLeft ? '0' : '0 16px';
      }
      return '0 24px';
    }};
  `
};
