import { css } from '@gf/cross-platform-lib/styled-components';

type ContainerType = { selected: boolean; disabledHover: boolean; shouldDisplayBorder: boolean; primaryColor: string };

export const baseStyles = {
  container: css<{
    selected: boolean;
    disabledHover: boolean;
    shouldDisplayBorder: boolean;
    primaryColor: string;
  }>`
    height: 24px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    border: ${({ shouldDisplayBorder, primaryColor }) =>
      shouldDisplayBorder ? `2px solid  ${primaryColor}` : '1px solid transparent'};
    background-color: ${({ selected }: ContainerType) => (selected ? '#323232' : '#dbdbdb')};
    border-radius: 4px;
    color: ${({ selected }: ContainerType) => (selected ? '#ffffff' : '#000000')};

    padding: 0 8px;
  `,
  icon: css<{ selected: boolean }>`
    height: auto;
    width: 14px;
    margin: 4px 4px 6px 0;
    filter: ${({ selected }) =>
      selected
        ? 'brightness(0) saturate(100%) invert(97%) sepia(3%) saturate(13%) hue-rotate(134deg) brightness(103%) contrast(107%)'
        : 'none'};
  `,
  expandIcon: css`
    margin-left: 9px;
    height: 5.25px;
    width: 8.75px;
  `,
  labelContainer: css<{ selected: boolean }>`
    color: ${({ selected }) => (selected ? '#f4f4f4' : '#323232')};
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
    height: 16px;
    align-items: center;
    display: flex;
  `
};
