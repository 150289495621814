import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ backgroundColor: string; isMobile: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.backgroundColor};
    width: 100%;
  `,
  contentWrapper: css<{ isMobile: boolean }>`
    padding: 16px 0;
    width: 100%;
    align-items: center;
  `,
  schoolProfile: css<{ showDistrictButton: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: ${props => (props.showDistrictButton ? '200px' : '16px')};
    align-items: center;
  `,
  schoolDetailWrapper: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  logo: css<{ isMobile: boolean }>`
    height: 72px;
    width: 72px;
  `,
  schoolDetailName: css<{ textColor: string; isMobile: boolean }>`
    letter-spacing: 0.41px;
    color: ${props => props.textColor};
    font-family: Rubik-Regular;
    font-size: 20px;
    line-height: 26px;
    padding: 0 16px;
  `,
  actionButtonsWrapper: css`
    flex-direction: row;
    gap: 28px;
    align-self: center;
  `,
  schoolDetailNameWrapper: css`
    flex: 1;
  `,
  viewAllSchoolsButton: css<{ color: string }>`
    width: 176px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-color: ${props => props.color};
    border-width: 1px;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
  `,
  sitePromoContainer: css<{ color: string; isMobile: boolean }>`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: ${({ isMobile }) => (isMobile ? '16px' : '0')};
    align-items: center;
  `
};
