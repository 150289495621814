import { safeFetch } from '../utils';
import { IPagination, IPaginationResponse, TeamInfo } from '@gf/cross-platform-lib/interfaces';
import { GetCurrentApplicationConfiguration, Method } from '@gf/cross-platform-lib/utils';

const appConfig = GetCurrentApplicationConfiguration();

export const getAllTeams = async (organizationId: string, pagination: IPagination) => {
  const url = `${appConfig.api.team.getUrl!(Method.GET)}/search?organizationId=${organizationId}&page=${
    pagination.page
  }&size=${pagination.size}`;
  return await safeFetch<IPaginationResponse<TeamInfo>>(url!);
};

export const getTeams = async (teamIds: string[]) => {
  const teamPromises = teamIds.map(async teamId => {
    const url = `${appConfig.api.team.getUrl!(Method.GET)}/${teamId}`;
    const teamInfo = await safeFetch<TeamInfo>(url!);
    return teamInfo.data;
  });
  const response = Promise.allSettled(teamPromises)
    .then(results => results.filter(result => result.status === 'fulfilled'))
    .then(results => results.map(result => (result as PromiseFulfilledResult<TeamInfo>).value));
  return response;
};
