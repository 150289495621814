import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  contentWrapper: css<{
    isShowBackgroundColor: boolean;
    isShowPrimaryColor: boolean;
    primaryColor: string;
    isMobile?: boolean;
  }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid rgb(151, 151, 151);
    margin-top: 8px;
    background-color: ${({ isShowBackgroundColor }) => (isShowBackgroundColor ? '#f4f4f4' : 'white')};
    ${({ isShowPrimaryColor, primaryColor }) =>
      isShowPrimaryColor &&
      `
      border-top-width: ${primaryColor.toLowerCase() === '#ffffff' ? `1px` : `5px`};
      border-top-color: ${primaryColor.toLowerCase() === '#ffffff' ? `rgb(151, 151, 151)` : `${primaryColor}`};
    `};
    width: 100%;
  `
};
