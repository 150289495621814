import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css<{ isMobile: boolean }>`
    border-left-width: 2px;
    border-left-color: #d3d3d3;
    display: flex;
    padding: 14px 16px 6px 16px;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    flex-basis: auto;
    ${({ isMobile }) =>
      isMobile &&
      `
      padding: 0 16px 8px 16px;
      border-left-width: 0;
      flex-direction: column;
      width: 100%;
    `};
  `,
  schoolTitle: css<{ isMobile: boolean }>`
    color: #161616;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    padding-right: 8px;
    padding-top: 2px;
    ${({ isMobile }) =>
      isMobile &&
      `
      padding-top: 0;
    `};
  `,
  involvedSchoolTags: css<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    ${({ isMobile }) =>
      isMobile &&
      `
      padding-top: 4px;
      white-space: nowrap;
      flex-wrap: nowrap;
      width: 100%;
    `};
  `,
  scrollViewWrapper: css<{ isMobile: boolean }>`
    ${({ isMobile }) =>
      isMobile &&
      `
      width: 100%;
    `};
  `
};
