import React from 'react';
import pluralize from 'pluralize';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { styles } from './InfoSection.styles';
import {
  getUnixTime,
  DATE_WITH_TIME_ZONE_FORMAT,
  isSameDayEvent,
  isPastDate,
  isFutureDate,
  testProperties,
  seasonCardComponent,
  passCardComponent
} from '@gf/cross-platform-lib/utils';
import { FundraiserProgressBar } from '@gf/cross-platform-lib/modules/event';

import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { Platform } from 'react-native';
import { EventSponsorshipView } from '@gf/cross-platform-lib/components';
import { IEventSponsorship } from '@gf/cross-platform-lib/interfaces';

const Wrapper = styled.View`
  ${styles.wrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.View`
  ${styles.subTitle};
`;

const EventInfo = styled.View`
  ${styles.eventInfo};
`;

const AdditionalInfoWrapper = styled.View`
  ${styles.additionalInfoWrapper};
`;

const ProgressWrapper = styled.View`
  ${styles.progressWrapper};
`;
export interface InfoSectionProps {
  activityLabel: string;
  eventTypeName: string;
  name: string;
  subTitle: string;
  startDateTime: string;
  endDateTime: string;
  timeZone: string;
  isSeason?: boolean;
  isFundraiser?: boolean;
  isDonationOnGoing?: boolean;
  isMobile: boolean;
  eventCount?: number | null;
  donationGoal?: number;
  donationsRaised?: number;
  barColor?: string;
  sponsor?: IEventSponsorship;
}

export const formatDate = (
  isFundraiser: boolean,
  isSeason: boolean,
  startDateTime: string,
  endDateTime: string,
  timeZone: string,
  showSameDayFormat?: boolean
) => {
  const isSameDay = isSameDayEvent(startDateTime, endDateTime, timeZone);
  const startDate = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  const endDate = getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  const startYear = startDate.format('YYYY');
  const endYear = endDate.format('YYYY');
  const isSameYear = startYear === endYear;
  const startDateTitle = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(
    isSameYear && isSeason ? 'MMM D' : 'MMM D, YYYY'
  );
  const endDateTitle = getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format('MMM D, YYYY');

  if (isFundraiser && isPastDate(startDateTime) && isFutureDate(endDateTime)) {
    if (endDate.diff(Date.now(), 'days') < 1) {
      return 'Last day to donate!';
    } else {
      return endDate.diff(Date.now(), 'days') + ' days left';
    }
  }
  if ((isFundraiser || showSameDayFormat) && isSameDay) return startDateTitle;

  return `${startDateTitle} - ${endDateTitle}`;
};

export const InfoSection = ({
  activityLabel,
  eventTypeName,
  name,
  subTitle,
  startDateTime,
  endDateTime,
  timeZone,
  isSeason = false,
  isFundraiser = false,
  isDonationOnGoing = false,
  isMobile,
  eventCount = 0,
  donationGoal,
  donationsRaised,
  barColor,
  sponsor
}: InfoSectionProps) => {
  const eventCountText = (count: number) => `${eventCount} ${pluralize('event', count)} scheduled`;
  const { isMobileApp } = useMediaQuery();

  return (
    <Wrapper isMobile={isMobile}>
      <Title {...testProperties('event-name')} isMobile={isMobile}>
        {name}
      </Title>
      <SubTitle>
        <StyledText
          typeToken='body02'
          color='#323232'
          {...testProperties(isSeason ? seasonCardComponent.date : passCardComponent.date)}
        >
          {formatDate(isFundraiser, isSeason, startDateTime, endDateTime, timeZone)}
        </StyledText>

        {eventCount && !isMobileApp ? (
          <EventInfo
            {...testProperties(seasonCardComponent.eventSchedule)}
            isMobile={isMobileApp}
            eventCount={eventCount}
          >
            <StyledText typeToken='label01' color='textInverse'>
              {eventCountText(eventCount)}
            </StyledText>
          </EventInfo>
        ) : null}
      </SubTitle>
      {eventCount && isMobileApp ? (
        <EventInfo
          {...testProperties(seasonCardComponent.eventSchedule)}
          isMobile={isMobileApp}
          eventCount={eventCount}
        >
          <StyledText typeToken='label01' color='textInverse'>
            {eventCountText(eventCount)}
          </StyledText>
        </EventInfo>
      ) : null}
      {!isFundraiser && (
        <AdditionalInfoWrapper>
          <StyledText {...testProperties(seasonCardComponent.sport)} typeToken='body02' color='#323232'>
            {eventTypeName || activityLabel}
          </StyledText>
          <StyledText {...testProperties(seasonCardComponent.level)} typeToken='body02' color='#323232'>
            {subTitle}
          </StyledText>
        </AdditionalInfoWrapper>
      )}
      {isSeason && !isDonationOnGoing && <EventSponsorshipView sponsor={sponsor} />}
      {!isMobile && donationGoal ? (
        <ProgressWrapper {...testProperties('progress-bar')} isWeb={Platform.OS === 'web'}>
          <FundraiserProgressBar
            donationGoal={donationGoal}
            donationsRaised={donationsRaised}
            barColor={barColor}
            isMobile={isMobile}
          />
        </ProgressWrapper>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
