export const styles = {
  item: {
    flexDirection: 'row',
    minHeight: 36,
    paddingLeft: 8,
    paddingRight: 11,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#C6C6CA',
    backgroundColor: '#ECECF0',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 1,
    borderRadius: 4,
    borderRightWidth: 1,
    borderRightColor: '#C6C6CA',
    marginTop: 4
  },
  selectedItem: {
    backgroundColor: '#D8D8DC'
  },
  iconStyle: {
    height: 18,
    width: 18
  },
  itemContainer: {
    flex: 1
  }
};
