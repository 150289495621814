import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css<{ color: string; enableOutline: boolean; fullWidth: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: ${({ enableOutline, color }) => (enableOutline ? `2px solid ${color}` : 'none')};
    color: ${({ color }) => color};
    border-radius: 4px;
    padding: 6px 16px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'min-content')};
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '326px')};
    height: 32px;
  `,
  siteUrl: css<{ color: string; enableBoldText: boolean }>`
    color: ${({ color }) => color};
    margin-right: 6px;
    font-weight: ${({ enableBoldText }) => (enableBoldText ? 'bold' : 'normal')};
    overflow: wrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: left;
  `
};
