import { CategorizedTeamInfo, TeamInfo } from '@gf/cross-platform-lib/interfaces/Team';
import { abbreviateWords } from '@gf/cross-platform-lib/utils';

export const categorizeTeams = (teams: TeamInfo[]): CategorizedTeamInfo => {
  const categorized: CategorizedTeamInfo = { Favorites: [] };

  for (const team of teams) {
    const activityName = team.activityName;

    const levelName = abbreviateWords(team.levelName, false);
    const teamCopy = { ...team, levelName };

    if (team.isFavorite) {
      categorized.Favorites.push(teamCopy);
    } else {
      categorized[activityName] = categorized[activityName] || [];
      categorized[activityName].push(teamCopy);
    }
  }

  // Sort within the Favorites category first
  categorized.Favorites.sort((a, b) => {
    let activityCompare = a.activityName.localeCompare(b.activityName);
    if (activityCompare !== 0) return activityCompare;

    let genderCompare = a.gender.localeCompare(b.gender);
    if (genderCompare !== 0) return genderCompare;

    return a.seasonName.localeCompare(b.seasonName);
  });

  // Sort the non-favorite activity categories
  const sortedActivities = Object.keys(categorized)
    .filter(activity => activity !== 'Favorites')
    .sort();

  // Create the final sorted result
  const teamsResult: CategorizedTeamInfo = { Favorites: categorized.Favorites }; // Keep Favorites at the top
  sortedActivities.forEach(activity => {
    const sortedTeams = [...categorized[activity]].sort((a, b) => {
      let genderCompare = a.gender.localeCompare(b.gender);
      if (genderCompare !== 0) return genderCompare;

      const seasonCompare = a.seasonName.localeCompare(b.seasonName);
      return seasonCompare; // Always return the result, even if it's 0
    }); // Sort within each non-favorite activity

    teamsResult[activity] = sortedTeams;
  });

  return teamsResult;
};
