import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css<{ isMobile: boolean }>`
    flex-direction: row;
    margin-right: 8px;
    justify-content: flex-start;
    align-items: center;
    max-width: 960px;
    width: 100%;
  `,
  legacyContainer: css`
    display: flex;
    padding: 0 32px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 960px;
    width: 100%;
  `,

  title: css`
    color: #000000;
    font-family: SourceSansPro-Semibold;
    font-size: 24px;
    height: 32px;
    letter-spacing: 0.27px;
  `,
  subTitle: css`
    color: #161616;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
  `,
  filterByActivityContainer: css`
    margin-top: 24px;
  `,
  listActivitiesWrapper: css<{ isMobile: boolean }>`
    width: 100%;
    height: auto;
  `,
  tagItem: css`
    margin-top: 8px;
    margin-right: 16px;
  `,
  emptyTag: css`
    margin-top: 12px;
  `,
  iconCircle: css<{ primaryColor: string }>`
    width: 20px;
    height: 20px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    background-color: ${props => props.primaryColor};
    margin-right: 4px;
  `,
  iconContainer: css`
    justify-content: center;
    align-items: center;
  `,
  countCircle: css`
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #191919;
    margin-right: 4px;
    padding-left: 4.5px;
    padding-right: 4.5px;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    gap: 10px;
  `,
  countText: css<{ isNativeApp: boolean }>`
    font-size: 12px;
    margin-top: ${({ isNativeApp }) => (isNativeApp ? '-3px' : '0px')};
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: #dddde1;
  `,
  filterItemContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  filterItemText: css`
    flex: 1;
    font-weight: 400;
    font-size: 16px;
  `,
  playoffsDivider: css`
    height: 1px;
    background-color: #c6c6ca;
    margin-top: 8px;
    margin-bottom: 4px;
  `
};
