import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    width: 100%;
    flex-direction: row;
    padding: 8px;
    padding-right: 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  `,
  leftContainer: css`
    flex-direction: row;
    align-items: center;
    flex: 1;
  `,
  rightContainer: css`
    flex-direction: row;
    align-items: center;
    margin-right: -8px; // Pulls chevron to line up with header close button
    flex: 1;
  `,
  teamName: css`
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 18px;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  `,
  seasonName: css`
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 18px;
  `,
  favoriteWrapper: css`
    margin-right: 18px;
  `,
  teamInfoWrapper: css`
    flex: 1;
    flex-direction: column;
    margin-left: 8px;
  `
};
