import React, { useEffect, useRef, useState } from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { styles } from './CategoryFiltersLegacy.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { listGendersAvailable, SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
import { Tag } from '../../EventList';
import { activityIcons, FILTER_ACTIVITY, FILTER_GENDER, FILTER_PLAYOFFS } from '@gf/cross-platform-lib/constants';
import { CategoriesType, EventSeason } from '@gf/cross-platform-lib/interfaces';
import { ScrollView, View } from 'react-native';
import { LoadingSpinner } from '@gf/cross-platform-lib/components';
import { schoolPage, testProperties } from '@gf/cross-platform-lib/utils';

const Container = styled(View)`
  ${styles.legacyContainer};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const ListActivitiesWrapper = styled(View)`
  ${styles.listActivitiesWrapper};
`;

const TagItem = styled(View)`
  ${styles.tagItem};
`;

const EmptyTag = styled(View)`
  ${styles.emptyTag};
`;

interface CategoryFiltersProps {
  events: EventSeason[];
  appliedFilter: SelectedFilter;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  primaryColor?: string;
  listCategories: CategoriesType;
  loadingCategories: boolean;
}

interface CategoryFilter {
  name: string;
  priority: number;
}

interface ReducerArguments {
  existingActivityNames: Set<string>;
  existingActivityFilters: Set<CategoryFilter>;
}

const createFilterObject = (name: string, priority: number) => {
  return {
    name,
    priority
  };
};

const processEvent = (
  activityName: string,
  existingActivityNames: Set<string>,
  existingActivities: Set<CategoryFilter>
) => {
  if (!existingActivityNames.has(activityName)) {
    existingActivityNames.add(activityName);
  } else {
    return;
  }
  if (activityName.toLowerCase() === 'fundraiser') {
    activityName && existingActivities.add(createFilterObject(activityName, 1));
    return;
  }
  activityName && existingActivities.add(createFilterObject(activityName, 2));
};

const filterReducer = ({ existingActivityNames, existingActivityFilters }: ReducerArguments, event: EventSeason) => {
  if (event.isSeason) {
    const activityName = event?.seasonTypeName
      ? capitalize(event?.seasonTypeName)
      : capitalize(event?.activity?.name) || '';
    processEvent(activityName, existingActivityNames, existingActivityFilters);
  } else {
    const activityName = event?.eventTypeName
      ? capitalize(event?.eventTypeName)
      : capitalize(event?.activity?.name) || '';
    processEvent(activityName, existingActivityNames, existingActivityFilters);
  }

  return {
    existingActivityNames,
    existingActivityFilters
  };
};

const sortFilters = (selectedFilter: SelectedFilter, filters: CategoryFilter[]) => {
  const selectedfilterSet = new Set([...selectedFilter.activities, ...selectedFilter.genders]);

  return filters.sort(function (filterA, filterB) {
    // Sort by selected filters
    if (!selectedfilterSet.has(filterA.name) && selectedfilterSet.has(filterB.name)) return 1;
    if (selectedfilterSet.has(filterA.name) && !selectedfilterSet.has(filterB.name)) return -1;

    // Sort by priority
    if (filterA.priority > filterB.priority) return 1;
    if (filterA.priority < filterB.priority) return -1;

    // Sort by alphabetically for objects that share the same priority
    if (filterA.name > filterB.name) return 1;
    if (filterA.name < filterB.name) return -1;
    return 0;
  });
};

const getAvailableActivities = (activities: { customSportName: string | null; id: string | number; name: string }[]) =>
  [...uniqBy(activities, act => act.customSportName || act.name)].map(activity => {
    const actName = activity.customSportName || activity.name;
    return createFilterObject(actName, actName === 'Fundraiser' ? 1 : 2);
  });

export const CategoryFiltersLegacy = ({
  events,
  applyFilter,
  appliedFilter,
  primaryColor,
  loadingCategories,
  listCategories
}: CategoryFiltersProps) => {
  const { activities = [], genders: allGenders = [], playOff } = listCategories || {};
  const filterDataLoaded = useRef(false);
  const { isMobile } = useMediaQuery();
  const [sortedFilterList, setSortedFilterList] = useState<CategoryFilter[]>([]);
  const { existingActivityNames, existingActivityFilters } = events.reduce(filterReducer, {
    existingActivityNames: new Set<string>([]),
    existingActivityFilters: new Set<CategoryFilter>([])
  });
  const filterLoaded = !loadingCategories && !isEmpty(listCategories);
  const filterList = filterLoaded ? [...getAvailableActivities(activities)] : [...Array.from(existingActivityFilters)];
  const genders = filterLoaded ? uniq(allGenders) : listGendersAvailable(events);
  const shouldShowActivities = !isEmpty(listCategories) ? activities.length > 0 : !isEmpty(events);
  const shouldShowPlayOff = filterLoaded ? !!playOff : events.some((event: any) => event.isPostSeason);
  const shouldShowGenders = genders.length > 0;
  if (shouldShowGenders)
    genders.forEach(gender => {
      gender && filterList.push(createFilterObject(gender, 3));
    });
  if (shouldShowPlayOff) filterList.push(createFilterObject('Playoffs', 4));

  const shouldDisplayFilters = filterList.length > 0;

  useEffect(() => {
    if (!filterDataLoaded.current) {
      filterDataLoaded.current = true;
      setSortedFilterList(sortFilters(appliedFilter, filterList));
    }
  }, [appliedFilter]);

  useEffect(() => {
    if (!loadingCategories && !isEmpty(listCategories)) {
      const allActivities = [...uniqBy(activities, act => act.customSportName || act.name)].map(activity => {
        const actName = activity.customSportName || activity.name;
        return createFilterObject(actName, actName === 'Fundraiser' ? 1 : 2);
      });
      const mappedAllGenders = uniq(allGenders).map(gender => createFilterObject(gender, 3));
      let filterList = [...allActivities, ...mappedAllGenders];
      if (playOff) filterList.push(createFilterObject('Playoffs', 4));

      setSortedFilterList(sortFilters(appliedFilter, filterList));
    }
  }, [loadingCategories, listCategories, appliedFilter]);
  const renderFilter = () => {
    return (
      <ScrollView
        horizontal={isMobile}
        contentContainerStyle={isMobile ? {} : { flexDirection: 'row', flexWrap: 'wrap' }}
        showsHorizontalScrollIndicator={false}
      >
        {sortedFilterList.map(filter => {
          if (
            shouldShowActivities &&
            (existingActivityNames.has(filter.name) ||
              activities.find(act => act.customSportName === filter.name || act.name === filter.name))
          ) {
            const activityIcon =
              events.find(
                e =>
                  e.eventTypeName?.toUpperCase() === filter.name.toUpperCase() ||
                  e.seasonTypeName?.toUpperCase() === filter.name.toUpperCase() ||
                  e.activity?.name?.toUpperCase() === filter.name.toUpperCase()
              )?.activity?.name ||
              activities.find(act => act.customSportName === filter.name || act.name === filter.name)?.name ||
              'unlabeled';
            const selected = appliedFilter.activities.map((e: string) => e.trim()).includes(filter.name);

            const applyFilterEventTypes = () => applyFilter(FILTER_ACTIVITY, selected, [filter.name]);
            return (
              <TagItem {...testProperties(schoolPage.filterItem)} key={`tagActivities-${filter.name}}`}>
                <Tag
                  label={filter.name}
                  renderIcon={
                    get(activityIcons, activityIcon.replace(/ /g, '').toLowerCase()) || activityIcons.unlabeled
                  }
                  onClick={applyFilterEventTypes}
                  selected={selected}
                  shouldDisplayBorder={filter.name === 'Fundraiser'}
                  primaryColor={primaryColor}
                />
              </TagItem>
            );
          } else if (shouldShowPlayOff && filter.name === 'Playoffs') {
            const selected = appliedFilter?.playoffs === false ? false : appliedFilter?.playoffs;

            return (
              <TagItem {...testProperties(schoolPage.filterItem)} key={`tagPlayoff-${Date.now()}`}>
                <Tag
                  label='Playoffs'
                  onClick={() => applyFilter(FILTER_PLAYOFFS, !!selected, !selected)}
                  selected={!!selected}
                />
              </TagItem>
            );
          } else if (shouldShowGenders && genders.includes(filter.name.trim())) {
            const selected = appliedFilter?.genders.includes(filter.name);
            return (
              <TagItem {...testProperties(schoolPage.filterItem)} key={`${filter.name}-${Date.now()}`}>
                <Tag
                  label={filter.name}
                  onClick={() => applyFilter(FILTER_GENDER, selected, [filter.name])}
                  selected={selected}
                />
              </TagItem>
            );
          } else {
            return null;
          }
        })}
      </ScrollView>
    );
  };

  return shouldDisplayFilters ? (
    <Container isMobile={isMobile} {...testProperties(schoolPage.filterCategory)}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <SubTitle style={{ marginRight: 20 }}>Filter by category {`(${filterList.length})`}</SubTitle>
        {loadingCategories && <LoadingSpinner spinnerSize={20} asSpinner usingLoadingIndicator></LoadingSpinner>}
      </View>
      <ListActivitiesWrapper>{renderFilter()}</ListActivitiesWrapper>
    </Container>
  ) : (
    <EmptyTag />
  );
};
