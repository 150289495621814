import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  homeAwayTag: css<{ backgroundColor: string; isMobile: boolean }>`
    color: ${({ backgroundColor }) => (backgroundColor === '#323232' ? 'white' : 'black')};
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 24px;
    border-radius: 12px;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#dbdbdb')};
    margin-left: 8px;
    ${({ isMobile }) =>
      isMobile &&
      `
      height: 18px;
    `};
  `,
  homeAwayTitle: css<{ color: string }>`
    ${baseStyles.homeAwayTitle}
  `,
  emptyTag: css<{ isMobile: boolean }>`
    width: 60px;
    height: 24px;
    margin-left: 8px;
    ${({ isMobile }) =>
      isMobile &&
      `
      height: 18px;
    `};
  `
};
