import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventInfoSection.styles';
import { Text, View } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import { eventCardComponent, testProperties } from '@gf/cross-platform-lib/utils';
import { EventSponsorshipView } from '@gf/cross-platform-lib/components';
import { IEventSponsorship } from '@gf/cross-platform-lib/interfaces';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const VenueNameTitle = styled(Text)`
  ${styles.venueNameTitle};
`;

const EventTitle = styled(Text)`
  ${styles.eventTitle};
`;

const SubTitle = styled(Text)`
  ${styles.subTitle};
`;
export interface EventInfoSectionProps {
  hasHomeAwayDisplay: boolean;
  activityLabel: string;
  eventTypeName: string;
  name: string;
  subTitle: string;
  isMobile: boolean;
  venueName: string;
  sponsor?: IEventSponsorship;
}

export const EventInfoSection = ({
  hasHomeAwayDisplay,
  activityLabel,
  eventTypeName,
  name,
  subTitle,
  isMobile,
  venueName,
  sponsor
}: EventInfoSectionProps) => {
  return (
    <Wrapper isMobile={isMobile} {...testProperties(eventCardComponent.venue)}>
      <EventTitle isMobile={isMobile} {...testProperties(eventCardComponent.name)}>
        {name}
      </EventTitle>
      <SubTitle {...testProperties(eventCardComponent.sport)}>{eventTypeName || activityLabel}</SubTitle>
      {!isEmpty(subTitle) && <SubTitle {...testProperties(eventCardComponent.activityLevels)}>{subTitle}</SubTitle>}
      {isMobile && !hasHomeAwayDisplay && (
        <VenueNameTitle {...testProperties(eventCardComponent.venue)}>{venueName}</VenueNameTitle>
      )}
      <EventSponsorshipView sponsor={sponsor} />
    </Wrapper>
  );
};
