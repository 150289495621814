import { styles } from '../HomeAwayFilter.styles';
import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { FilterType } from '@gf/cross-platform-lib/utils/filterUtils';
import { Tag } from '../../EventList';
import { schoolPage, testProperties } from '@gf/cross-platform-lib/utils';
import { FILTER_HOMEAWAY, LOCATION } from '@gf/cross-platform-lib/constants';
import { View } from 'react-native';

const Container = styled(View)`
  ${styles.container};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const HomeAwayWrapper = styled(View)`
  ${styles.homeAwayWrapper};
`;

const TagItem = styled(View)`
  ${styles.tagItem};
`;

interface HomeAwayFilterProps {
  appliedFilter: FilterType;
  applyFilter: (type: string, selected: boolean, params: string[]) => void;
}

export const HomeAwayFilterLegacy = ({ applyFilter, appliedFilter }: HomeAwayFilterProps) => {
  const { isMobile } = useMediaQuery();
  const homeAway = appliedFilter?.homeAway || [];
  const selectedHome = homeAway.includes(LOCATION.HOME);
  const selectedAway = homeAway.includes(LOCATION.AWAY);

  return (
    <Container isMobile={isMobile} {...testProperties(schoolPage.filterHomeAway)}>
      <SubTitle>Filter by Home/Away</SubTitle>
      <HomeAwayWrapper>
        <TagItem {...testProperties(schoolPage.filterItem)} key={LOCATION.HOME}>
          <Tag
            label={LOCATION.HOME}
            renderIcon='involved-school.png'
            onClick={() => applyFilter(FILTER_HOMEAWAY, selectedHome, [LOCATION.HOME])}
            selected={selectedHome}
          />
        </TagItem>
        <TagItem {...testProperties(schoolPage.filterItem)} key={LOCATION.AWAY}>
          <Tag
            label={LOCATION.AWAY}
            renderIcon='plane.png'
            onClick={() => applyFilter(FILTER_HOMEAWAY, selectedAway, [LOCATION.AWAY])}
            selected={selectedAway}
          />
        </TagItem>
      </HomeAwayWrapper>
    </Container>
  );
};
