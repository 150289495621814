import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  supportLinks: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  wrapper: css<{ displaySchoolSearch?: boolean }>`
    max-width: 960px;
    width: 100%;
  `,

  linkWrapper: css`
    margin-left: 4px;
  `,

  horizontalDivider: css<{ isMobile?: boolean }>`
    max-width: 100%;
    margin-top: ${({ isMobile }) => (isMobile ? '24px' : '32px')}
    margin-horizontal: ${({ isMobile }) => (isMobile ? '32px' : '0px')}
    border-bottom-width: 1px;
    border-color: #d3d3d3;
  `
};
