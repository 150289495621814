export enum SchoolType {
  'favorite school' = 0,
  'high school' = 1,
  'middle school' = 2,
  'elementary school' = 3
}

export interface SchoolDistrictInfo {
  huddle_id: string;
  logo: string;
  name: string;
  num_of_events_today: number;
  num_of_events_upcoming: number;
  school_type: string;
}
