import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventBody.styles';
import { BuyEventTicketBtn } from '../BuyEventTicketBtn';
import { ActivityItem } from '../ActivityItem';
import { EventTicketDateTime } from '../EventTicketDateTime';
import { Featured } from '../Featured';
import { EventTag } from '../EventTag';
import { EventInfoSection } from '../EventInfoSection';
import { InfoSection } from '../InfoSection';
import { Text, TouchableOpacity, View } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { EventSeasonCombined, IEventSponsorship } from '@gf/cross-platform-lib/interfaces';
import { eventCardComponent, getValue, testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const DateSection = styled(View)`
  ${styles.dateSection};
`;

const RightArrowSection = styled(View)`
  ${styles.rightArrowSection};
`;

const FeaturedSection = styled(View)`
  ${styles.featuredSection};
`;

const MobileFooter = styled(View)`
  ${styles.mobileFooter};
`;

const MobileRightSection = styled(View)`
  ${styles.mobileRightSection};
`;

const MobileLeftSection = styled(View)`
  ${styles.mobileLeftSection};
`;

const MobileTag = styled(View)`
  ${styles.mobileTag};
`;

const IconWrapper = styled(View)`
  ${styles.iconWrapper};
`;

const MoreWrapper = styled(View)`
  ${styles.moreWrapper};
`;

const MobileInfo = styled(View)`
  ${styles.mobileInfo};
`;

const MobileHeader = styled(View)`
  ${styles.mobileHeader};
`;
export interface EventBodyProps {
  isShowFeaturedSection: boolean;
  isAway: boolean;
  hasHomeAwayDisplay: boolean;
  isAllDayEvent: boolean;
  primaryColor?: string;
  textColor: string;
  isMobilePass: boolean;
  subTitle: string;
  isMobile: boolean;
  event: EventSeasonCombined;
  eventLink: string;
  changeShowFooter: () => void;
  isShowFooter: boolean;
  venueName: string;
  isSameDay: boolean;
  isShowMoreLess: boolean;
  sponsor?: IEventSponsorship;
}

export const EventBody = ({
  isShowFeaturedSection,
  isAway,
  hasHomeAwayDisplay,
  isAllDayEvent,
  primaryColor,
  textColor,
  isMobilePass,
  subTitle,
  isMobile,
  eventLink,
  changeShowFooter,
  isShowFooter,
  venueName,
  isSameDay,
  isShowMoreLess,
  event,
  sponsor
}: EventBodyProps) => {
  const renderEventInfoSection = () =>
    isMobilePass ? (
      <InfoSection
        activityLabel={getValue(event?.activity?.name)}
        eventTypeName={getValue(event?.eventTypeName)}
        name={getValue(event?.title)}
        subTitle={subTitle}
        startDateTime={event?.startDateTime}
        endDateTime={event?.endDateTime}
        timeZone={getValue(event?.timeZone)}
        isMobile={isMobile}
      />
    ) : (
      <EventInfoSection
        hasHomeAwayDisplay={hasHomeAwayDisplay}
        activityLabel={getValue(event?.activity?.name)}
        eventTypeName={getValue(event?.eventTypeName)}
        name={getValue(event?.title)}
        subTitle={subTitle}
        isMobile={isMobile}
        venueName={venueName}
        sponsor={sponsor}
      />
    );

  return (
    <Wrapper isMobile={isMobile}>
      {isMobile ? (
        <>
          <MobileInfo>
            {!isMobilePass && (
              <MobileLeftSection>
                <ActivityItem
                  name={getValue(event?.activity?.name)}
                  hidePaddingLeft={isMobilePass}
                  isMobile={isMobile}
                />
                <DateSection isMobile={isMobile}>
                  <EventTicketDateTime
                    isMobile={isMobile}
                    startDateTime={event?.startDateTime}
                    timeZone={getValue(event?.timeZone)}
                    isAllDayEvent={isAllDayEvent}
                    endDateTime={event?.endDateTime}
                    isSameDay={isSameDay}
                  />
                </DateSection>
              </MobileLeftSection>
            )}
            <MobileRightSection isMobilePass={isMobilePass}>
              {(hasHomeAwayDisplay || isShowFeaturedSection || isMobilePass) && (
                <MobileHeader>
                  <MobileTag>
                    {isMobilePass && (
                      <ActivityItem
                        name={getValue(event?.activity?.name)}
                        hidePaddingLeft={isMobilePass}
                        isMobile={isMobile}
                      />
                    )}
                  </MobileTag>
                  <MobileTag>
                    {isShowFeaturedSection && (
                      <FeaturedSection>
                        <Featured primaryColor={primaryColor} textColor={textColor} isMobile={isMobile} />
                      </FeaturedSection>
                    )}
                    <EventTag
                      hasHomeAwayDisplay={hasHomeAwayDisplay}
                      isShowPass={isMobilePass}
                      isAway={isAway}
                      isMobile={isMobile}
                    />
                  </MobileTag>
                </MobileHeader>
              )}
              {renderEventInfoSection()}
            </MobileRightSection>
          </MobileInfo>
          <MobileFooter>
            <TouchableOpacity
              onPress={() => {
                changeShowFooter();
              }}
              testID='show-more-less-button'
            >
              <MoreWrapper {...testProperties(eventCardComponent.expandButton)}>
                {isShowMoreLess && (
                  <>
                    <IconWrapper>
                      <Carbonicons color='#282828' name={isShowFooter ? 'chevron-up' : 'chevron-down'} size={20} />
                    </IconWrapper>
                    <Text
                      style={{
                        fontSize: 14,
                        maxWidth: 100
                      }}
                    >
                      {isShowFooter ? 'less' : 'more'}
                    </Text>
                  </>
                )}
              </MoreWrapper>
            </TouchableOpacity>
            <BuyEventTicketBtn isSeason={false} isMobilePass={isMobilePass} link={eventLink} event={event} />
          </MobileFooter>
        </>
      ) : (
        <>
          <FeaturedSection>
            {isShowFeaturedSection && (
              <Featured primaryColor={primaryColor} textColor={textColor} isMobile={isMobile} />
            )}
          </FeaturedSection>
          <DateSection isMobile={isMobile}>
            <EventTag
              isMobile={isMobile}
              hasHomeAwayDisplay={hasHomeAwayDisplay}
              isShowPass={isMobilePass}
              isAway={isAway}
            />
            <ActivityItem name={getValue(event?.activity?.name)} hidePaddingLeft={isMobilePass} isMobile={isMobile} />
            {!isMobilePass && (
              <EventTicketDateTime
                startDateTime={event?.startDateTime}
                timeZone={getValue(event?.timeZone)}
                isAllDayEvent={isAllDayEvent}
                endDateTime={event?.endDateTime}
                isSameDay={isSameDay}
                isMobile={isMobile}
              />
            )}
          </DateSection>
          {renderEventInfoSection()}
          <RightArrowSection>
            <BuyEventTicketBtn isSeason={false} isMobilePass={isMobilePass} link={eventLink} event={event} />
          </RightArrowSection>
        </>
      )}
    </Wrapper>
  );
};
