import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  buttonText: css`
    ${baseStyles.buttonText}
  `,
  buttonSection: css`
    ${baseStyles.buttonSection}
  `
};
