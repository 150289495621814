import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const CloseIconRounded = (props: SvgProps) => (
  <Svg width={props.width || 16} height={props.height || 16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M.447.447a1.528 1.528 0 012.16 0L8.01 5.85 13.411.447a1.528 1.528 0 012.16 2.16L10.17 8.01l5.402 5.402a1.528 1.528 0 01-2.16 2.16L8.009 10.17l-5.402 5.402a1.528 1.528 0 01-2.16-2.16L5.85 8.009.447 2.607a1.528 1.528 0 010-2.16z'
      fill='#000'
    />
  </Svg>
);
