import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  wrapper: css<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 16px;
    ${({ isMobile }) =>
      isMobile &&
      `
      flex-direction: column;
    `};
  `,
  dateSection: css<{ isMobile: boolean }>`
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${({ isMobile }) =>
      isMobile &&
      `
      flex-direction: column;
      padding-top: 4px;
      padding-bottom: 8px;
    `};
  `,
  rightArrowSection: css`
    ${baseStyles.rightArrowSection}
  `,
  featuredSection: css`
    ${baseStyles.featuredSection}
  `,
  mobileFooter: css`
    ${baseStyles.mobileFooter}
  `,
  mobileLeftSection: css`
    ${baseStyles.mobileLeftSection}
  `,
  mobileTag: css`
    ${baseStyles.mobileTag}
  `,
  iconWrapper: css`
    ${baseStyles.iconWrapper}
  `,
  moreWrapper: css`
    ${baseStyles.moreWrapper}
  `,
  mobileRightSection: css`
    ${baseStyles.mobileRightSection}
  `,
  mobileInfo: css`
    ${baseStyles.mobileInfo}
  `,
  mobileHeader: css`
    ${baseStyles.mobileHeader}
  `
};
