import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  wrapper: css<{ isMobile: boolean }>`
    margin-top: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ isMobile }) => isMobile && `margin-top: 16px;`};
  `,
  titleText: css`
    ${baseStyles.titleText}
  `,
  subText: css`
    ${baseStyles.subText}
  `,
  buttonResetFilters: css`
    ${baseStyles.buttonResetFilters}
  `,
  buttonText: css`
    ${baseStyles.buttonText}
  `,
  horizontalLine: css<{ isMobile: boolean }>`
    ${baseStyles.horizontalLine}
  `,
  horizontalWrapper: css<{ isMobile: boolean }>`
    ${baseStyles.horizontalWrapper}
  `
};
