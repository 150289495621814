import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  headerWrapper: css`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    height: 32px;
  `,
  verticalCenter: css`
    flex-direction: column;
    justify-content: center;
    height: 38px;
    flex: 1;
  `,
  title: css`
    font-family: Rubik-SemiBold;
    font-size: 16px;
    max-width: 250px;
  `
};
