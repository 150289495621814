import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ backgroundColor: string; headerHeight: number }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    width: 100%;
    height: ${({ headerHeight }) => headerHeight}px;
    align-items: center;
  `,
  contentWrapper: css<{ isMobile: boolean }>`
    padding: 16px;
    height: 100%;
    width: 100%;
    max-width: 960px;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'space-between')};
    align-items: ${({ isMobile }) => (isMobile ? 'space-between' : 'center')};
    flex: 1;
  `,
  textContainer: css`
    margin-left: 8px;
    flex: 1;
  `,
  title: css<{ textColor: string }>`
    color: ${props => props.textColor};
    font-family: 'ChangaOne-Regular';
    font-size: 20px;
    min-width: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: left;
  `,
  teamsLink: css<{ textColor: string }>`
    color: ${({ textColor }) => textColor};
    font-family: 'Roboto-Regular';
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: left;
    margin-top: 4px;
  `,
  sitePromoContainer: css<{ isMobile: boolean }>`
    flex-direction: row;
    justify-content: center;
    width: auto;
    margin-top: ${({ isMobile }) => (isMobile ? 12 : 0)}px;
    margin-left: ${({ isMobile }) => (isMobile ? 0 : 12)}px;
    flex: 1;
  `,
  viewDistrictBtnContainer: css<{ isMobile: boolean }>`
    flex-direction: row;
    justify-content: center;
    margin-top: ${({ isMobile }) => (isMobile ? 12 : 0)}px;
    margin-left: ${({ isMobile }) => (isMobile ? 0 : 12)}px;
    flex: 1;
  `,
  centerRow: css`
    flex-direction: row;
    align-items: center;
    flex: 2;
  `,
  viewDistrictBtn: css<{ color: string }>`
    display: flex;
    border: ${({ color }) => `2px solid ${color}`};
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 4px;
    width: 100%;
    height: 32px;
    padding: 6px 16px;
  `,
  viewSchoolsText: css<{ color: string }>`
    color: ${({ color }) => color};
    margin-right: 6px;
    font-weight: bold;
    overflow: wrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: left;
  `
};
