import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventListSectionWrapper: css<{ isMobile?: boolean; isShowingLess?: boolean }>`
    margin-horizontal: 16px;
    margin-bottom: ${({ isShowingLess }) => (isShowingLess ? '0' : '16px')};
  `,
  eventListBar: css<{ isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 16px;
    padding-horizontal: 16px;
  `,
  listBarTitle: css`
    flex-direction: row;
    align-items: center;
  `,
  iconWrapper: css`
    margin-left: 8px;
  `,
  sectionText: css`
    color: #323232;
    font-family: Rubik-SemiBold;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  eventListWrapper: css<{ isShowingLess: boolean }>`
    display: ${({ isShowingLess }) => (isShowingLess ? 'none' : 'flex')};
  `,
  adBannerWrapper: css<{ isMobile: boolean }>`
    align-items: center;
    margin-top: ${({ isMobile }) => (isMobile ? '16px' : '24px')};
    margin-bottom: ${({ isMobile }) => (!isMobile ? '16px' : '12px')};
  `
};
