import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';
export const styles = {
  ...baseStyles,
  involvedSchoolTags: css<{ isMobile: boolean }>`
    ${baseStyles.involvedSchoolTags};
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  `
};
