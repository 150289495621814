import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const ExampleSchoolContextualLDComponent = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.messageText}>Testing School based context for QA.</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 300,
    height: 300,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },

  messageContainer: {
    position: 'absolute',
    top: '10%',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 10,
    padding: 10
  },
  messageText: {
    color: '#123ABC',
    textAlign: 'center',
    fontSize: 16
  }
});

export default ExampleSchoolContextualLDComponent;
