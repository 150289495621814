import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventTag.styles';

import { isEmpty, schoolPage, testProperties } from '@gf/cross-platform-lib/utils';
import { Text, View } from 'react-native';

const HomeAwayTag = styled(View)`
  ${styles.homeAwayTag};
`;

const HomeAwayTitle = styled(Text)`
  ${styles.homeAwayTitle};
`;

const EmptyTag = styled(View)`
  ${styles.emptyTag};
`;

export interface EventTagProps {
  hasHomeAwayDisplay: boolean;
  isShowPass: boolean;
  isAway: boolean;
  isMobile: boolean;
}

export const EventTag = ({ hasHomeAwayDisplay, isShowPass, isAway, isMobile }: EventTagProps) => {
  let title = '';
  let backgroundColor = '';

  const showEmptyTag = isMobile ? <View /> : <EmptyTag isMobile={isMobile} />;

  if (isShowPass) {
    title = 'Pass';
    backgroundColor = '#f0f0f0';
  } else if (hasHomeAwayDisplay) {
    title = isAway ? 'Away' : 'Home';
    backgroundColor = isAway ? '#323232' : '#dbdbdb';
  }

  return !isEmpty(title) ? (
    <HomeAwayTag {...testProperties(schoolPage.eventTag)} backgroundColor={backgroundColor} isMobile={isMobile}>
      <HomeAwayTitle color={backgroundColor}>{title}</HomeAwayTitle>
    </HomeAwayTag>
  ) : (
    showEmptyTag
  );
};
