import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './ListHeader.styles';
import { CloseButton } from '@gf/cross-platform-lib/providers/DrawerProvider/CloseButton';
import { useDrawerContext } from '@gf/cross-platform-lib/providers/DrawerProvider';
import { View, Text } from 'react-native';

interface ListHeaderProps {
  headerTitle?: string;
  drawerId: string;
  variation?: 'default' | 'variation-done-button';
  closeFunction?: () => void;
}

const ListHeader = ({ headerTitle = '', drawerId, variation = 'default', closeFunction }: ListHeaderProps) => {
  const { closeDrawer } = useDrawerContext();

  const handleClose = () => {
    closeFunction && closeFunction();
    closeDrawer(drawerId);
  };

  return (
    <HeaderWrapper>
      <VerticalCenter>
        <Title numberOfLines={2}>{headerTitle}</Title>
      </VerticalCenter>
      <CloseButton onPress={handleClose} variation={variation} />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled(View)`
  ${styles.headerWrapper}
`;
const VerticalCenter = styled(View)`
  ${styles.verticalCenter}
`;
const Title = styled(Text)`
  ${styles.title}
`;

export default ListHeader;
