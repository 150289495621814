import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  noEventFoundDataSection: css`
    ${baseStyles.noEventFoundDataSection}
  `,
  noEventFoundIcon: css`
    ${baseStyles.noEventFoundIcon}
  `,
  noEventFoundText: css<{ marginBottom: number }>`
    ${baseStyles.noEventFoundText}
  `,
  titleText: css`
    ${baseStyles.titleText}
  `,
  titleWrapper: css<{ isMobile?: boolean }>`
    ${baseStyles.titleWrapper}
  `
};
