import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { TouchableOpacity, View, Text } from 'react-native';
import { CloseIconRounded } from '@gf/cross-platform-lib/components';

interface CloseButtonProps {
  onPress: () => void;
  variation: 'default' | 'variation-done-button';
}
export const CloseButton = ({ onPress, variation = 'default' }: CloseButtonProps) => {
  const renderButton = () => {
    switch (variation) {
      case 'variation-done-button':
        return (
          <DoneBackgroundContainer testID='variation-done-button'>
            <DoneText>Done</DoneText>
          </DoneBackgroundContainer>
        );
      default:
        return <CloseIconRounded testID='variation-default' height={16} width={16} />;
    }
  };

  return (
    <CloseButtonContainer testID='close-button' onPress={onPress}>
      {renderButton()}
    </CloseButtonContainer>
  );
};

const CloseButtonContainer = styled(TouchableOpacity)`
  ${styles.closeButtonContainer}
`;
const DoneBackgroundContainer = styled(View)`
  ${styles.doneBackgroundContainer}
`;
const DoneText = styled(Text)`
  ${styles.doneText}
`;
