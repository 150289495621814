import { css } from '@gf/cross-platform-lib/styled-components';
import { styles as baseStyles } from './base.styles';
export const styles = {
  ...baseStyles,
  container: css`
    ${baseStyles.container};
    :hover,
    :active {
      background-color: #161616;
    }
  `
};
