import { Venue } from '../interfaces';
import { isEmpty } from './isEmpty';
import { titleCase } from './titleCase';

const getCityState = (venue?: Venue) => {
  const { city = '', state = '' } = venue || {};
  if (!isEmpty(city) || !isEmpty(state)) {
    const stateValue = isEmpty(state) ? '' : `, ${state.toUpperCase()}`;
    const stateNoComma = isEmpty(state) ? '' : `${state.toUpperCase()}`;
    return `${isEmpty(city) ? `${stateNoComma}` : `${titleCase(city)}${stateValue}`}`;
  }
  return '';
};

const getVenueCityState = (venue: Venue) => {
  const cityState = getCityState(venue);

  return `${venue?.name} ${isEmpty(cityState) ? '' : `(${cityState})`}`;
};

const getCityStateZip = (venue?: Venue) => {
  const cityState = getCityState(venue);
  const { zip = '' } = venue || {};

  return `${cityState}${!isEmpty(cityState) && !isEmpty(zip) ? ' ' : ''}${zip}`;
};

export { getVenueCityState, getCityState, getCityStateZip };
