import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css<{ isMobile: boolean }>`
    border-top-width: 2px;
    border-top-color: #d3d3d3;
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  `,
  tagWrapper: css<{ isShowInvolvedSchools: boolean; isMobile: boolean }>`
    padding: 16px;
    display: flex;
    flex-direction: row;
    width: ${({ isShowInvolvedSchools, isMobile }) => (isShowInvolvedSchools && !isMobile ? '50%' : '100%')};
    flex-wrap: wrap;
    align-content: flex-start;
    padding-bottom: ${({ isShowInvolvedSchools, isMobile }) => (isShowInvolvedSchools && isMobile ? '0' : '8px')};
  `
};
