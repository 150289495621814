import { useDrawerContext } from '@gf/cross-platform-lib/providers';
import { useState, useRef, useEffect, SetStateAction } from 'react';
import { View, Dimensions } from 'react-native';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks/useMediaQuery';

const screenHeight = Dimensions.get('window').height;
export const dropdownMaxHeight = screenHeight * 0.6;
export interface DropdownCoordinates {
  x: number;
  y: number;
}

export const useDropdownAPI = ({
  testSetPositionReady,
  dismissFunction,
  mobileDrawerId
}: MultiSelectDialogAPIProps) => {
  const { isMobile } = useMediaQuery();
  const { toggleDrawer } = useDrawerContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownCoordinates>({ x: 0, y: 0 });
  const [isPositionReady, setIsPositionReady] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  const dropdownRef = useRef<View>(null);

  useEffect(() => {
    if (isDropdownOpen) {
      getPosition();
    } else {
      setIsPositionReady(false);
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (testSetPositionReady !== undefined) {
      setIsPositionReady(testSetPositionReady);
    }
  }, [testSetPositionReady]);

  const toggleDropdown = () => {
    if (isMobile && mobileDrawerId) {
      isMobile && isDropdownOpen && setIsDropdownOpen(false);
      toggleDrawer(mobileDrawerId);
    } else {
      setIsDropdownOpen(isDropdownOpen => {
        if (isDropdownOpen && dismissFunction) {
          dismissFunction();
        }
        return !isDropdownOpen;
      });
    }
  };

  const handleContentSizeChange = (contentWidth: number, contentHeight: number) => {
    setDropdownHeight(contentHeight > dropdownMaxHeight ? dropdownMaxHeight : contentHeight + 20);
  };

  const getPosition = () => {
    dropdownRef.current?.measure((x, y, width, height, pageX, pageY) => {
      setDropdownPosition({
        x: pageX,
        y: pageY + height + 8
      });
      setIsPositionReady(true);
    });
  };

  return {
    dropdownRef,
    toggleDropdown,
    getPosition,
    isDropdownOpen,
    isPositionReady,
    dropdownPosition,
    dropdownHeight,
    handleContentSizeChange,
    isMobile
  };
};

type MultiSelectDialogAPIProps = {
  testSetPositionReady?: SetStateAction<boolean>;
  dismissFunction?: () => void;
  mobileDrawerId?: string;
};
