export const styles = {
  dropdownButton: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    backgroundColor: '#ECECF0',
    borderColor: '#C6C6CA',
    borderWidth: 1,
    borderRadius: 100,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 8
  },
  dropdownButtonText: {
    fontSize: 14,
    lineHeight: 22.4,
    fontWeight: '400'
  },
  dropdownListContainer: {
    width: 264,
    padding: 8,
    backgroundColor: '#ECECF0',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#C6C6CA',
    shadowColor: '#15152A',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.32,
    shadowRadius: 9,
    elevation: 5
  },
  modalBackdrop: {
    position: 'absolute',
    width: 241,
    maxHeight: 264
  },
  placeholderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconStyle: {
    marginLeft: 4
  }
};
