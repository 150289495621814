import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { ScrollView, TouchableWithoutFeedback, View } from 'react-native';
import { ATHLETIC_TEAMS_LIST } from '@gf/cross-platform-lib/providers';
import ListHeader from '@gf/cross-platform-lib/providers/DrawerProvider/DrawerComponents/ListHeader/ListHeader';
import { CategoryHeader } from './CategoryHeader';
import { TeamListItem } from './TeamListItem';
import { CategorizedTeamInfo } from '@gf/cross-platform-lib/interfaces/Team';
import { SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
interface AthleticTeamsListProps {
  schoolName: string;
  isNativeApp: boolean;
  isMobile: boolean;
  toggleFavorite: (team: any) => void;
  getActivityIconName: (activityName: string) => string;
  categorizedTeams: CategorizedTeamInfo;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  resetFilters: () => void;
  appliedFilter: SelectedFilter;
}

export const AthleticTeamsListView = ({
  schoolName,
  isNativeApp,
  isMobile,
  toggleFavorite,
  getActivityIconName,
  categorizedTeams,
  applyFilter,
  resetFilters,
  appliedFilter
}: AthleticTeamsListProps) => {
  return (
    <TouchableWithoutFeedback>
      <Container isNativeApp={isNativeApp} isMobile={isMobile}>
        <ListHeader headerTitle={`${schoolName} teams`} drawerId={ATHLETIC_TEAMS_LIST} />
        <ScrollView showsHorizontalScrollIndicator={false} bounces={false} showsVerticalScrollIndicator={false}>
          <TouchableWithoutFeedback>
            <View>
              {Object.keys(categorizedTeams).map((activityName, index) => {
                if (activityName === 'Favorites' && categorizedTeams['Favorites'].length === 0) return null;
                return (
                  <CategoryContainer key={`${activityName}-${index}`}>
                    <CategoryHeader activityName={activityName} activityIconName={getActivityIconName(activityName)} />
                    {categorizedTeams[activityName].map(team => {
                      return (
                        <TeamListItem
                          key={team.id}
                          levelName={team.levelName}
                          gender={team.gender}
                          seasonName={team.seasonName}
                          isFavorite={!!team.isFavorite}
                          toggleFavorite={() => toggleFavorite(team)}
                          activityName={team.activityName}
                          activityIconName={getActivityIconName(team.activityName)}
                          applyFilter={applyFilter}
                          resetFilters={resetFilters}
                          appliedFilter={appliedFilter}
                        />
                      );
                    })}
                  </CategoryContainer>
                );
              })}
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </Container>
    </TouchableWithoutFeedback>
  );
};
const Container = styled(View)`
  ${styles.container}
`;
const CategoryContainer = styled(View)`
  ${styles.categoryContainer}
`;

export default AthleticTeamsListView;
