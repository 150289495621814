import React, { useState } from 'react';
import { EventSeason, IEventSponsorship, SchoolInterface } from '@gf/cross-platform-lib/interfaces';

import { EventBody } from '../EventBody';
import { EventFooter } from '../EventFooter';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventItem.styles';
import {
  getCityState,
  getLevelGenderTitle,
  getVenueCityState,
  invertColor,
  isAggregatorSchool,
  isEmpty,
  isSameDayEvent,
  schoolPage,
  testProperties
} from '@gf/cross-platform-lib/utils';
import uniqBy from 'lodash/uniqBy';
import isNumber from 'lodash/isNumber';
import { MobilePassFooter } from '../MobilePassFooter';
import { View } from 'react-native';
import { useFetchSchools } from '../../../../AcquisitionV2/queries/schoolQueries';

const ContentWrapper = styled(View)`
  ${styles.contentWrapper};
`;

export interface EventItemProps {
  isMobile: boolean;
  eventItem: EventSeason;
  isFeatured: boolean;
  isAway: boolean;
  schoolType: string;
  primaryColor?: string;
  schoolId: string;
  sponsor?: IEventSponsorship;
}

const MemoizedEventItem = ({
  eventItem,
  isFeatured,
  isAway,
  schoolType,
  primaryColor,
  isMobile,
  schoolId,
  sponsor
}: EventItemProps) => {
  const [isShowFooter, setIsShowFooter] = useState(!isMobile);
  const venueCityState = eventItem.venue ? getVenueCityState(eventItem.venue) : '';

  const textColor = primaryColor?.toUpperCase() === 'TRANSPARENT' ? '#000000' : invertColor(primaryColor ?? '', true);
  const involvedSchoolIds = [
    ...(!isEmpty(eventItem.schoolHuddleId) ? [eventItem.schoolHuddleId!] : []),
    ...(!isEmpty(eventItem.opponentSchoolId) ? [eventItem.opponentSchoolId!] : []),
    ...(!isEmpty(eventItem.taggedSchoolHuddleIds) ? eventItem.taggedSchoolHuddleIds : [])
  ].filter(id => id !== schoolId);
  const involvedSchoolsQueryRes = useFetchSchools(involvedSchoolIds ?? [], undefined, false);
  const involvedSchools =
    involvedSchoolsQueryRes
      .filter(item => !!item.data?.data && !isAggregatorSchool(item.data?.data?.gofanSchoolType || ''))
      .map(item => item.data?.data) || [];
  const isAggregatorOrg = isAggregatorSchool(schoolType);
  const venueTitleOnState = isAggregatorOrg ? eventItem?.venue?.name || '' : getCityState(eventItem.venue);
  const mobileVenueTitle = isAggregatorOrg ? venueTitleOnState : venueCityState;
  const subTitle = getLevelGenderTitle(
    eventItem.eventTypeName ?? '',
    eventItem.levels ?? [],
    eventItem.genders,
    eventItem.optionalTypeDescription
  );
  const isSameDay = isSameDayEvent(eventItem.startDateTime, eventItem.endDateTime, eventItem.timeZone);
  const isMobilePass = isNumber(eventItem.redemptionWindow);
  const theme = eventItem?.theme;
  const isPlayoff = eventItem.isPostSeason!;

  return (
    <ContentWrapper
      isShowBackgroundColor={!isAggregatorOrg && isAway && !isMobilePass}
      isShowPrimaryColor={isMobilePass}
      primaryColor={primaryColor || '#000000'}
      isMobile={isMobile}
      {...testProperties(schoolPage.eventCard)}
    >
      <EventBody
        isAway={isAway}
        hasHomeAwayDisplay={!isAggregatorOrg}
        isAllDayEvent={eventItem.isAllDayEvent}
        primaryColor={primaryColor}
        textColor={textColor}
        isShowFeaturedSection={isFeatured}
        isMobilePass={isMobilePass}
        subTitle={subTitle}
        isMobile={isMobile}
        eventLink={`/event/${eventItem.id}?schoolId=${schoolId}`}
        changeShowFooter={() => {
          setIsShowFooter(!isShowFooter);
        }}
        isShowFooter={isShowFooter}
        venueName={isAggregatorOrg ? getCityState(eventItem.venue) : eventItem?.venue?.name || ''}
        isSameDay={isSameDay}
        isShowMoreLess={!isMobilePass || isFeatured || isPlayoff}
        event={eventItem}
        sponsor={sponsor}
      />
      {isMobilePass && isShowFooter ? (
        <>
          {(isFeatured || isPlayoff) && (
            <MobilePassFooter isMobile={isMobile} isPlayoff={isPlayoff} isFeatured={isFeatured} />
          )}
        </>
      ) : (
        <>
          {isShowFooter && (
            <EventFooter
              isAllDayEvent={eventItem.isAllDayEvent}
              startDateTime={eventItem.startDateTime}
              endDateTime={eventItem.endDateTime}
              isPlayoff={isPlayoff}
              isFeatured={isFeatured}
              venueTitle={!isMobile ? venueCityState : mobileVenueTitle}
              isMobile={isMobile}
              isShowInvolvedSchools={isAggregatorOrg && !isEmpty(involvedSchools)}
              involvedSchools={uniqBy((involvedSchools as SchoolInterface[]) || [], 'name')}
              homeSchoolId={eventItem.schoolHuddleId!}
              isSameDay={isSameDay}
              timeZone={eventItem.timeZone ?? ''}
              theme={theme}
            />
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export const EventItem = React.memo(MemoizedEventItem);
