import { css } from '@gf/cross-platform-lib/styled-components';

export const filterDrawerStyles = {
  container: css<{ containerHeight: string }>`
    display: flex;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 16px;
    padding-bottom: 32px;
    height: ${({ containerHeight }) => containerHeight};
  `,
  subtitleContainer: css`
    margin: 16px 0;
  `,
  subtitleText: css`
    font-family: Rubik-SemiBold;
    font-size: 16px;
  `
};
