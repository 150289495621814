import { css } from 'styled-components';

export default {
  footerWrapper: css`
    display: flex;
    margin: auto;
    margin-bottom: 92px;
    max-width: 960px;
    padding: 16px;
    width: 100%;
  `,
  aboutDetailsContainer: css`
    border-radius: 4px;
    border: 1px solid rgb(219, 219, 219);
    flex-direction: row;
    margin-top: 16px;
    padding: 16px;
  `,
  locationSection: css`
    flex: 2;
  `,
  socialSection: css`
    align-items: center;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  `,
  twitterIcon: css``,
  facebookIconWrapper: css``,
  leftMarginWrapper: css`
    margin-left: 16px;
  `
};
