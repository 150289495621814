import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  noEventFoundDataSection: css`
    text-align: center;
  `,
  noEventFoundIcon: css`
    height: 140px;
    margin: 16px 0 24px 0;
    align-items: center;
  `,
  noEventFoundText: css<{ marginBottom: number }>`
    color: #323232;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 18px;
    text-align: center;
    max-height: 100%;
  `,
  titleText: css`
    color: #323232;
    font-family: Rubik-SemiBold;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  titleWrapper: css<{ isMobile?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    flex-direction: row;
    padding: ${({ isMobile }) => (isMobile ? '0 48px' : '0 16px')};
  `
};
