import React, { ReactNode, SetStateAction } from 'react';
import { View, TouchableWithoutFeedback, TouchableOpacity, StyleSheet, Modal, ScrollView } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { ChevronDown, ChevronUp } from '../Icons/Carbonicons/collections';
import { CloseIcon } from '@gf/cross-platform-lib/components/Icons';
import { useDropdownAPI } from './useDropdownAPI';

export const Dropdown = ({
  renderPlaceholder,
  children,
  filtersSelected,
  cancelFunction,
  testSetPositionReady,
  dismissFunction,
  mobileDrawerId
}: DropdownProps) => {
  const {
    dropdownRef,
    toggleDropdown,
    getPosition,
    isDropdownOpen,
    isPositionReady,
    dropdownPosition,
    dropdownHeight,
    handleContentSizeChange
  } = useDropdownAPI({
    testSetPositionReady,
    dismissFunction,
    mobileDrawerId
  });

  const renderIcon = () => {
    if (isDropdownOpen) {
      return <ChevronUp fill={'#191919'} height={16} width={16} />;
    } else if (filtersSelected) {
      return (
        <TouchableOpacity onPress={cancelFunction} testID='close-icon'>
          <CloseIcon fill={'#191919'} height={16} width={16} />
        </TouchableOpacity>
      );
    } else {
      return <ChevronDown fill={'#191919'} height={16} width={16} />;
    }
  };

  const handleDropdownPress = () => {
    toggleDropdown();
    getPosition();
  };

  return (
    <View>
      <View ref={dropdownRef}>
        <DropdownButton onPress={handleDropdownPress} testID='dropdown-button'>
          {renderPlaceholder(isDropdownOpen, 0)}
          <View style={styles.iconStyle}>{renderIcon()}</View>
        </DropdownButton>
        <Modal
          visible={isDropdownOpen && isPositionReady}
          transparent
          animationType='none'
          onRequestClose={toggleDropdown}
        >
          <TouchableWithoutFeedback onPress={toggleDropdown} testID='dropdown-touchable'>
            <View style={StyleSheet.absoluteFill}>
              <DropdownListContainer
                style={[{ top: dropdownPosition.y, left: dropdownPosition.x, maxHeight: dropdownHeight }]}
                onContentSizeChange={handleContentSizeChange}
                testID='dropdown-list-container'
              >
                {children}
              </DropdownListContainer>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    </View>
  );
};

export default Dropdown;

const DropdownListContainer = styled(ScrollView)`
  ${styles.dropdownListContainer}
`;

const DropdownButton = styled(TouchableOpacity)`
  ${styles.dropdownButton}
`;

export type DropdownProps = {
  renderPlaceholder: (isOpen: boolean, selectedCount: number) => ReactNode;
  children: ReactNode[];
  filtersSelected: boolean;
  cancelFunction?: () => void;
  testSetPositionReady?: SetStateAction<boolean>;
  dismissFunction?: () => void;
  mobileDrawerId?: string;
};
