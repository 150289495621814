import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css<{ isNativeApp: boolean; isMobile: boolean }>`
    display: flex;
    transition: all 0.35s;
    padding: 32px;
    height: ${({ isNativeApp, isMobile }) => (isMobile ? '100%' : isNativeApp ? '100vh' : 'calc(100% - 4px)')};
    width: 360px;
  `,
  schoolTypeHeader: css`
    margin-top: 16px;
  `,
  favoriteCardWrapper: css`
    padding-vertical: 16px;
  `,
  schoolNameText: css`
    font-family: Rubik-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #161616;
  `,
  schoolEventsInfoText: css`
    font-family: Rubik-Regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #3a3a3a;
  `,
  schoolInfoContainer: css`
    flex-direction: row;
    justify-content: space-between;
    padding-vertical: 16px;
  `,
  schoolInfo: css`
    flex-direction: row;
    align-items: center;
    gap: 16px;
    flex-shrink: 1;
  `,
  schoolText: css`
    flex-direction: column;
    flex-shrink: 1;
    margin-right: 16px;
  `
};
