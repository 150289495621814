import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  `,
  imageWrapper: css`
    margin-right: 8px;
  `,
  activityText: css`
    font-size: 16px;
    font-family: Rubik-SemiBold;
  `
};
