import { baseStyles } from './base.styles';
import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  ...baseStyles,
  homeAwayWrapper: css`
    ${baseStyles.homeAwayWrapper};
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  `,
  iconCircle: css<{ primaryColor: string }>`
    width: 20px;
    height: 20px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    background-color: ${props => props.primaryColor};
    margin-right: 4px;
  `,
  iconContainer: css`
    justify-content: center;
    align-items: center;
  `,
  countCircle: css`
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #191919;
    margin-right: 4px;
    padding-left: 4.5px;
    padding-right: 4.5px;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    gap: 10px;
  `,
  filterItemText: css`
    flex: 1;
    font-weight: 400;
    font-size: 16px;
  `,
  filterItemContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
};
