import React from 'react';
import { useAthleticTeamsListAPI } from './useAthleticTeamsListAPI';
import AthleticTeamsListView from './AthleticTeamsListView';
import { SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
interface FilterSettings {
  applyFilter: (type: string, selected: boolean, params: any) => void;
  resetFilters: () => void;
  appliedFilter: SelectedFilter;
}
interface AthleticTeamsListContainerProps {
  schoolName: string;
  schoolId: string;
  filterSettings: FilterSettings;
}

export const AthleticTeamsListContainer = ({
  schoolName,
  schoolId,
  filterSettings
}: AthleticTeamsListContainerProps) => {
  const props = useAthleticTeamsListAPI(schoolId);
  return <AthleticTeamsListView schoolName={schoolName} {...props} {...filterSettings} />;
};

export default AthleticTeamsListContainer;
