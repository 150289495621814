import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { LayoutContent } from '../app/Layout';
import { SchoolContainer } from './container';
import { useMediaQuery, useParams, useRouter, useTracking } from '@gf/cross-platform-lib/hooks';
import { GOFAN_APP_PAGES, NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { getEventListPageViewProps, getFilterProps } from '@gf/cross-platform-lib/hooks/tracking/utils';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { ScrollViewProvider } from '@gf/cross-platform-lib/providers';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

export type Params = {
  id?: string;
  shortName?: string;
  redirectFromSchoolDistrict?: boolean;
};

const Page = () => {
  const params = useParams() as Params;
  const { trackEventListPageView, trackFilterEvent } = useTracking();
  const { isMobile, loading: isMediaLoading } = useMediaQuery();
  const { replace } = useRouter();
  const isFirstRender = React.useRef(false);
  const id = params?.id;
  const shortName = params?.shortName;
  const redirectFromSchoolDistrict = !!params?.redirectFromSchoolDistrict;
  const [pageTitle, setPageTitle] = useState(GOFAN_APP_PAGES.school.pageTitle);
  const [hideFooter, setHideFooter] = useState(true);

  let paramString: string = '';
  try {
    paramString = JSON.stringify(params);
  } catch (e: any) {
    recordError(e, {
      originatingFunction: 'SchoolScreen-Page',
      customMessage: 'Failed to stringify `params` during initial rendering of SchoolScreen Page.',
      errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
      data: { params },
      expected: true
    });
  }

  const [renderKey, setRenderKey] = useState(id || shortName);
  const isMobileApp = Platform.OS === 'android' || Platform.OS === 'ios';

  //FORCE RE-RENDER SCHOOL DETAILS COMPONENT WHEN DEEP-LINKING
  useEffect(() => {
    if (isMobileApp) {
      const subscription = Linking.addEventListener('url', params => {
        setRenderKey(params.url);
      });
      return () => {
        subscription.remove();
      };
    }
  }, []);

  useEffect(() => {
    if (redirectFromSchoolDistrict) {
      setRenderKey(id || shortName);
      return;
    }
    const filterProps = getFilterProps(params);
    if (!isFirstRender.current) {
      isFirstRender.current = true;
      trackEventListPageView(getEventListPageViewProps(params));
    }
    if (filterProps.filters && filterProps.filters.length) {
      trackFilterEvent(getFilterProps(params));
    }
    setRenderKey(id || shortName);
  }, [paramString]);

  const toggleFooterVisibility = (shouldHide: boolean) => setHideFooter(shouldHide);

  return id || shortName ? (
    <LayoutContent title={pageTitle} hideFooter={hideFooter}>
      {!isMediaLoading && (
        <GestureHandlerRootView>
          <ScrollViewProvider>
            <SchoolContainer
              key={renderKey}
              id={(id || shortName) as string}
              filterQuery={params || {}}
              replaceURLQueryString={(queryString: string) => {
                const path = params.shortName
                  ? GOFAN_APP_PAGES.shortName.getPath(params.shortName, queryString)
                  : GOFAN_APP_PAGES.school.getPath(params.id, queryString);

                replace(path);
              }}
              isMobile={isMobile}
              setPageTitle={setPageTitle}
              toggleFooterVisibility={toggleFooterVisibility}
            />
          </ScrollViewProvider>
        </GestureHandlerRootView>
      )}
    </LayoutContent>
  ) : null;
};

export const SchoolScreen = () => {
  return <Page />;
};
