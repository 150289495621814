import React, { createContext, useContext } from 'react';
import { Platform, ScrollView } from 'react-native';
import { PullRefreshProvider } from '../PullRefreshProvider';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

type ScrollBehavior = 'auto' | 'smooth';
type ScrollOptions = { x?: number; y?: number; behavior?: ScrollBehavior };

interface ScrollViewContextType {
  scrollToTop: (scrollOptions?: ScrollOptions) => void;
  scrollTo: (scrollOptions: ScrollOptions) => void;
}

interface ScrollViewProviderProps {
  children: React.ReactNode;
}

const ScrollViewContext = createContext<ScrollViewContextType>({
  scrollTo: (_scrollOptions: ScrollOptions) => {},
  scrollToTop: (_scrollOptions?: ScrollOptions) => {}
});

const ScrollViewProvider = ({ children }: ScrollViewProviderProps) => {
  const { isMobile } = useMediaQuery();
  const scrollRef = React.useRef<ScrollView>(null);
  const scrollToTop = (scrollOptions?: ScrollOptions) => {
    if (Platform.OS === 'web' && !isMobile) {
      const container = document.getElementById('root-container');
      container?.scrollTo({ top: 0, left: 0, behavior: scrollOptions?.behavior || 'smooth' });
    } else {
      scrollRef.current?.scrollTo({ x: 0, y: 0 });
    }
  };
  const scrollTo = ({ x = 0, y = 0, behavior = 'smooth' }: ScrollOptions) => {
    if (Platform.OS === 'web' && !isMobile) {
      const container = document.getElementById('root-container');
      container?.scrollTo({ top: y, left: x, behavior });
    } else {
      scrollRef.current?.scrollTo({ x, y });
    }
  };
  const value = {
    scrollToTop,
    scrollTo
  };
  return (
    <ScrollViewContext.Provider value={value}>
      <PullRefreshProvider scrollRef={scrollRef}>{children}</PullRefreshProvider>
    </ScrollViewContext.Provider>
  );
};

const useScrollView = () => {
  const context = useContext(ScrollViewContext);
  return context;
};

export { useScrollView, ScrollViewProvider };
