// @ts-nocheck
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../types';

export const CheckboxEmpty = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' fill={props.color || 'currentColor'} viewBox='0 0 18 18' {...props}>
    <Path
      d='M0 3.25C0 1.45507 1.45507 0 3.25 0H14.75C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25ZM3.25 1.5C2.2835 1.5 1.5 2.2835 1.5 3.25V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5H3.25Z'
      fill='#191919'
    />
  </Svg>
);
