import React, { useState } from 'react';
import { EventSeason, IEventSponsorship } from '@gf/cross-platform-lib/interfaces';

import { MobilePassFooter } from '../MobilePassFooter';
import { InfoSection } from '../InfoSection';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './SeasonItem.styles';
import {
  eventCardComponent,
  getLevelGenderTitle,
  invertColor,
  schoolPage,
  testProperties
} from '@gf/cross-platform-lib/utils';
import { ActivityItem } from '../ActivityItem';
import { BuyEventTicketBtn } from '../BuyEventTicketBtn';
import { Featured } from '../Featured';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { Text, TouchableOpacity, View } from 'react-native';

const ContentWrapper = styled(View)`
  ${styles.contentWrapper};
`;

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const DateSection = styled(View)`
  ${styles.dateSection};
`;

const RightArrowSection = styled(View)`
  ${styles.rightArrowSection};
`;

const FeaturedSection = styled(View)`
  ${styles.featuredSection};
`;

const MobileFooter = styled(View)`
  ${styles.mobileFooter};
`;

const MobileRightSection = styled(View)`
  ${styles.mobileRightSection};
`;

const SeasonTag = styled(View)`
  ${styles.seasonTag};
`;

const SeasonText = styled(Text)`
  ${styles.seasonText};
`;

const MobileTag = styled(View)`
  ${styles.mobileTag};
`;

const IconWrapper = styled(View)`
  ${styles.iconWrapper};
`;

const MoreWrapper = styled(View)`
  ${styles.moreWrapper};
`;

const MobileInfo = styled(View)`
  ${styles.mobileInfo};
`;

const MobileHeader = styled(View)`
  ${styles.mobileHeader};
`;

export interface SeasonItemProps {
  isMobile: boolean;
  seasonItem: EventSeason;
  primaryColor?: string;
  schoolId: string;
  eventCount?: number;
  sponsor?: IEventSponsorship;
}

const MemoizedSeasonItem = ({ seasonItem, primaryColor, isMobile, eventCount, sponsor }: SeasonItemProps) => {
  const [isShowFooter, setIsShowFooter] = useState(!isMobile);
  const isSeason = seasonItem.isSeason;
  const textColor = primaryColor?.toUpperCase() === 'TRANSPARENT' ? '#000000' : invertColor(primaryColor || '', true);

  const subTitle = getLevelGenderTitle(
    seasonItem.seasonTypeName ?? '',
    seasonItem.levels ?? [],
    seasonItem.genders,
    seasonItem.optionalTypeDescription
  );
  const eventLink = `/season/${seasonItem.id}`;

  return (
    <ContentWrapper isMobile={isMobile} primaryColor={primaryColor ?? '#000000'}>
      <Wrapper isMobile={isMobile} {...testProperties(schoolPage.eventCard)}>
        {isMobile ? (
          <>
            <MobileInfo>
              <MobileRightSection>
                <MobileHeader>
                  <MobileTag>
                    <ActivityItem name={seasonItem.activity?.name ?? ''} hidePaddingLeft={true} isMobile={isMobile} />
                  </MobileTag>
                  <MobileTag>
                    <FeaturedSection>
                      <Featured primaryColor={primaryColor} textColor={textColor} isMobile={isMobile} />
                    </FeaturedSection>
                    <SeasonTag {...testProperties(schoolPage.eventTag)} isMobile={isMobile}>
                      <SeasonText>Season</SeasonText>
                    </SeasonTag>
                  </MobileTag>
                </MobileHeader>
                <InfoSection
                  activityLabel={seasonItem.activity?.name ?? ''}
                  eventTypeName={seasonItem.seasonTypeName ?? ''}
                  name={seasonItem.title ?? ''}
                  subTitle={subTitle}
                  startDateTime={seasonItem.startDateTime!}
                  endDateTime={seasonItem.endDateTime!}
                  timeZone={seasonItem.timeZone ?? ''}
                  isSeason
                  isMobile={isMobile}
                  eventCount={seasonItem?.eventIds?.length || 0}
                  sponsor={sponsor}
                />
              </MobileRightSection>
            </MobileInfo>
            <MobileFooter {...testProperties(eventCardComponent.expandButton)}>
              <TouchableOpacity
                onPress={() => {
                  setIsShowFooter(!isShowFooter);
                }}
              >
                <MoreWrapper>
                  <IconWrapper>
                    <Carbonicons color='#282828' name={isShowFooter ? 'chevron-up' : 'chevron-down'} size={20} />
                  </IconWrapper>
                  <Text style={{ fontSize: 14, maxWidth: 100 }}>{isShowFooter ? 'less' : 'more'}</Text>
                </MoreWrapper>
              </TouchableOpacity>
              <BuyEventTicketBtn isSeason={isSeason} isMobilePass={false} link={eventLink} event={seasonItem} />
            </MobileFooter>
          </>
        ) : (
          <>
            <FeaturedSection>
              <Featured primaryColor={primaryColor} textColor={textColor} isMobile={isMobile} />
            </FeaturedSection>
            <DateSection isMobile={isMobile}>
              <SeasonTag {...testProperties(schoolPage.eventTag)}>
                <SeasonText>Season</SeasonText>
              </SeasonTag>
              <ActivityItem name={seasonItem.activity?.name ?? ''} hidePaddingLeft={false} isMobile={isMobile} />
            </DateSection>
            <InfoSection
              activityLabel={seasonItem.activity?.name ?? ''}
              eventTypeName={seasonItem.seasonTypeName ?? ''}
              name={seasonItem.title ?? ''}
              subTitle={subTitle}
              startDateTime={seasonItem.startDateTime}
              endDateTime={seasonItem.endDateTime}
              timeZone={seasonItem.timeZone ?? ''}
              isSeason
              isMobile={isMobile}
              eventCount={eventCount}
              sponsor={sponsor}
            />
            <RightArrowSection>
              <BuyEventTicketBtn isSeason={isSeason} isMobilePass={false} link={eventLink} event={seasonItem} />
            </RightArrowSection>
          </>
        )}
      </Wrapper>
      {isShowFooter && <MobilePassFooter isMobile={isMobile} isFeatured isPlayoff={false} />}
    </ContentWrapper>
  );
};

export const SeasonItem = React.memo(MemoizedSeasonItem);
