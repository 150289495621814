import * as React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './NoEventFound.styles';
import { GFImage } from '@gf/cross-platform-lib/components';
import { Text, View, TouchableOpacity } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { schoolPage, testProperties } from '@gf/cross-platform-lib/utils';

const NoEventFoundDataSection = styled(View)`
  ${styles.noEventFoundDataSection};
`;

const NoEventFoundIcon = styled(View)`
  ${styles.noEventFoundIcon};
`;

const NoEventFoundText = styled(Text)`
  ${styles.noEventFoundText};
`;

const TitleWrapper = styled(View)<{ isMobile: boolean }>`
  ${styles.titleWrapper};
`;

const TitleText = styled(Text)`
  ${styles.titleText};
`;

const RefreshButton = styled(View)`
  transform: rotate(-45deg);
`;

const noEventIcon = 'no-events.png';

interface NoEventFoundProps {
  isMobile: boolean;
  handleRefreshEvents: () => void;
}

export const NoEventFound = ({ isMobile, handleRefreshEvents }: NoEventFoundProps) => {
  return (
    <NoEventFoundDataSection>
      <TitleWrapper isMobile={isMobile}>
        <TitleText>Upcoming events (0)</TitleText>
        <TouchableOpacity onPress={handleRefreshEvents} {...testProperties(schoolPage.reloadEvent)}>
          <RefreshButton>
            <Carbonicons name='refresh' size={isMobile ? 16 : 20} />
          </RefreshButton>
        </TouchableOpacity>
      </TitleWrapper>
      <NoEventFoundIcon>
        <GFImage name={noEventIcon} height={140} width={140} />
      </NoEventFoundIcon>
      <NoEventFoundText marginBottom={isMobile ? 8 : 4}>
        There are no upcoming school events at this time.
      </NoEventFoundText>
      <NoEventFoundText marginBottom={0}>Check back soon.</NoEventFoundText>
    </NoEventFoundDataSection>
  );
};
