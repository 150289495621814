import { EventCombined, ProductType, PurchasedTicket } from '@gf/cross-platform-lib/interfaces';
import { DATE_WITH_TIME_ZONE_FORMAT, getUnixTime, isFutureDate, isPastDate } from './dateUtils';

export const isEventActivityFundraiser = (event: EventCombined | null | undefined): boolean => {
  if (!event) return false;
  return event.activity?.name === 'Fundraiser';
};

export const getDaysLeft = (startDateTime: string, endDateTime: string, timeZone: string): number => {
  const endDate = getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);

  if (isPastDate(startDateTime) && isFutureDate(endDateTime)) {
    return endDate.diff(Date.now(), 'days');
  } else {
    return 0;
  }
};

export const combineDonationOngoingData = (products: PurchasedTicket[][]): PurchasedTicket[][] => {
  // This method filters out all items from the products array that contain donation_ongoing products,
  // and appends new array items containing all donation_ongoing tickets per event to the end of the products array
  // The end result is a combining of all the individual donation_ongoing items into single instances per event
  const ongoingFundraisers: PurchasedTicket[][] = [];
  const eventIdsToDonationOngoingData: { [key: string]: PurchasedTicket[] } = {};

  products.forEach(product => {
    if (product[0].productType === ProductType.DONATION_ONGOING) {
      product.forEach(ticket => {
        if (eventIdsToDonationOngoingData[ticket.eventId]) {
          eventIdsToDonationOngoingData[ticket.eventId] = [...eventIdsToDonationOngoingData[ticket.eventId], ticket];
        } else {
          eventIdsToDonationOngoingData[ticket.eventId] = [ticket];
        }
      });
    }
  });

  Object.values(eventIdsToDonationOngoingData).forEach(ongoingFundraiser => {
    ongoingFundraisers.push(ongoingFundraiser);
  });

  const modifiedProducts = products.filter(product => product[0].productType !== ProductType.DONATION_ONGOING);

  if (ongoingFundraisers.length > 0) {
    ongoingFundraisers.forEach(ongoingFundraiser => modifiedProducts.push(ongoingFundraiser));
  }

  return modifiedProducts;
};
