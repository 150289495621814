// @ts-nocheck
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../types';

export const CheckboxFilled = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' fill={props.color || 'currentColor'} viewBox='0 0 18 18' {...props}>
    <Path
      d='M3.25 0C1.45507 0 0 1.45508 0 3.25V14.75C0 16.5449 1.45508 18 3.25 18H14.75C16.5449 18 18 16.5449 18 14.75V3.25C18 1.45507 16.5449 0 14.75 0H3.25ZM14.28 6.28064L7.52628 13.0266C7.23333 13.3192 6.75868 13.319 6.46591 13.0262L3.72014 10.2803C3.42726 9.98741 3.42727 9.51253 3.72018 9.21965C4.01308 8.92677 4.48795 8.92678 4.78084 9.21968L6.99658 11.4356L13.22 5.21936C13.513 4.92664 13.9879 4.92691 14.2806 5.21998C14.5734 5.51304 14.5731 5.98791 14.28 6.28064Z'
      fill='#191919'
    />
  </Svg>
);
