import { useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useGetAllTeams } from '@gf/cross-platform-lib/modules/AcquisitionV2/queries/TeamQueries';
import { CategorizedTeamInfo, TeamInfo } from '@gf/cross-platform-lib/interfaces';
import { categorizeTeams } from '@gf/cross-platform-lib/utils/teams';
import { activityIcons } from '@gf/cross-platform-lib/constants';
import { useCustomTracking } from '@gf/cross-platform-lib/hooks/tracking/useCustomTracking';
import { Fan } from '@gf/cross-platform-lib/models';

export const useAthleticTeamsListAPI = (schoolId: string) => {
  const fan = Fan.getInstance();
  const { isNativeApp, isMobile } = useMediaQuery();
  const teamsData = useGetAllTeams(schoolId);
  const { trackFavoriteTeam } = useCustomTracking();
  const [categorizedTeams, setCategorizedTeams] = useState<CategorizedTeamInfo>({});

  useEffect(() => {
    if (teamsData) {
      const favoritedTeamsData = teamsData.map(team => ({
        ...team,
        isFavorite: fan.favoriteTeamIds?.includes(team.id)
      }));
      setCategorizedTeams(categorizeTeams(favoritedTeamsData));
    }
  }, [teamsData]);

  const sortTeams = (teams: TeamInfo[]) =>
    [...teams].sort((a, b) => {
      let activityCompare = a.activityName.localeCompare(b.activityName);
      if (activityCompare !== 0) return activityCompare;
      let genderCompare = a.gender.localeCompare(b.gender);
      if (genderCompare !== 0) return genderCompare;
      return a.seasonName.localeCompare(b.seasonName);
    });

  const favoriteTeam = useCallback(
    (team: TeamInfo) => {
      setCategorizedTeams(prev => {
        trackFavoriteTeam(team.id, team.organizationId);
        const updatedTeams = { ...prev };
        const newTeam = { ...team, isFavorite: true };

        // Remove team from its current activity
        updatedTeams[team.activityName] = updatedTeams[team.activityName].filter(t => t.id !== team.id);

        // If activity becomes empty, remove it
        if (updatedTeams[team.activityName].length === 0) {
          delete updatedTeams[team.activityName];
        }

        // Add team to Favorites and sort
        updatedTeams.Favorites = sortTeams([...(updatedTeams.Favorites || []), newTeam]);
        fan.toggleFavoriteTeam(team.id);
        return categorizeTeams(Object.values(updatedTeams).flat()); // Re-categorize and sort all teams
      });
    },
    [setCategorizedTeams, trackFavoriteTeam]
  );

  const unfavoriteTeam = useCallback(
    (team: TeamInfo) => {
      setCategorizedTeams(prev => {
        const updatedTeams = { ...prev };
        const newTeam = { ...team, isFavorite: false };

        // Remove team from Favorites
        updatedTeams.Favorites = updatedTeams.Favorites.filter(t => t.id !== team.id);

        // Add team to its original activity (or create if needed) and sort
        updatedTeams[team.activityName] = sortTeams([...(updatedTeams[team.activityName] || []), newTeam]);
        fan.toggleFavoriteTeam(team.id);
        return categorizeTeams(Object.values(updatedTeams).flat()); // Re-categorize and sort all teams
      });
    },
    [setCategorizedTeams]
  );

  const toggleFavorite = useCallback(
    (team: TeamInfo) => {
      if (team.isFavorite) {
        unfavoriteTeam(team);
      } else {
        favoriteTeam(team);
      }
    },
    [favoriteTeam, unfavoriteTeam]
  );

  const getActivityIconName = (activityName?: string): string => {
    if (!activityName) return ''; // Handle the case where no activityName is provided

    const normalizedName = activityName.replaceAll(' ', '').toLowerCase();
    const iconName = activityIcons[normalizedName as keyof typeof activityIcons];
    return iconName ?? 'activity-icons/unlabeled.png';
  };

  return { isNativeApp, isMobile, categorizedTeams, toggleFavorite, getActivityIconName };
};
