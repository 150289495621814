import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  buttonText: css`
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    padding-right: 8px;
    color: #ffffff;
  `,
  buttonSection: css`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    background-color: #323232;
    border: 0;
    border-radius: 4px;
    padding: 6px 28px;
    height: 32px;
  `
};
