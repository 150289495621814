import { css } from '@gf/cross-platform-lib/styled-components';
import { mediaQuery as media } from '@gf/cross-platform-lib/utils';

export const baseStyles = {
  container: css<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 32px;
    max-width: 960px;
    width: 100%;
  `,
  subTitle: css`
    color: #161616;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
  `,
  homeAwayWrapper: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;

    ${media.tablet`
      width:100%;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      flex-wrap: nowrap
    `};
  `,
  tagItem: css`
    margin-top: 8px;
    margin-right: 16px;

    ${media.tablet`
      margin-top: 4px;
    `};
  `,
  filterItemText: css`
    flex: 1;
    font-weight: 400;
    font-size: 16px;
  `,
  countText: css<{ isNativeApp: boolean }>`
    font-size: 12px;
    margin-top: ${({ isNativeApp }) => (isNativeApp ? '-3px' : '0px')};
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: #dddde1;
  `,
  filterItemContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
};
