import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ isMobile: boolean }>`
    border-top-width: 2px;
    border-top-color: #d3d3d3;
    display: flex;
    flex-direction: row;
    ${({ isMobile }) =>
      isMobile &&
      `
      padding-bottom: 8px;
      flex-direction: column;
    `};
  `,
  tagWrapper: css<{ isMobile: boolean }>`
    padding: 16px;
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    width: 100%;

    ${({ isMobile }) =>
      isMobile &&
      `
      width: 100%;
      padding-bottom: 0;
      flex-wrap: wrap;
    `};
  `
};
