import React from 'react';
import { EventList, SchoolFooter, SchoolHeader } from '../../components';
import { SchoolsResponse } from '@gf/cross-platform-lib/models';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './SchoolDetailView.styles';
import {
  CategoriesType,
  EventSeason,
  EventSeasonCombined,
  IEventSponsorship,
  School,
  SchoolDistrictInfo
} from '@gf/cross-platform-lib/interfaces';
import { useLaunchDarklyContextSync } from '@gf/cross-platform-lib/hooks';
import { SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
import { SchoolSearch } from '@gf/cross-platform-lib/modules/search';
import {
  AdBanner,
  CategoriesLoadingSkeleton,
  EventListLoadingSkeleton,
  ExternalLink,
  LoadingSpinner,
  StyledButton,
  StyledText
} from '@gf/cross-platform-lib/components';
import { Platform, Pressable, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import {
  ATHLETIC_TEAMS_LIST,
  DrawerContent,
  SCHOOL_DISTRICT_LIST,
  useDrawerContext,
  usePullRefresh,
  useScrollView,
  useSearchPageContext
} from '@gf/cross-platform-lib/providers';
import { DEFAULT_SHOW_MORE_SIZE, REFRESH_METHOD } from '@gf/cross-platform-lib/constants';
import { PullDownLoadingIcon } from '@gf/cross-platform-lib/components/PullDownLoadingIcon/PullDownLoadingIcon';
import isEmpty from 'lodash/isEmpty';
import { getTicketBackgroundTextColors, schoolPage, testProperties } from '@gf/cross-platform-lib/utils';
import { ArrowRight } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { SchoolDistrictList } from '../../components/SchoolDistrictList';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';
import { useGetAllSchoolsForSchoolDistrict } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import ExampleSchoolContextualLDComponent from './ExampleSchoolContextualLDComponent';
import RenderFilters from './RenderFilters';
import AthleticTeamsListContainer from '../../components/AthleticTeamsList/AthleticTeamsListContainer';
import { AdPageType } from '@gf/cross-platform-lib/components/AdBanner/util';

const Container = styled(View)`
  ${styles.container}
`;

const FooterContainer = styled(View)`
  ${styles.footerContainer}
`;

const SupportLinksWrapper = styled(View)`
  ${styles.supportLinksWrapper};
`;

const SchoolSearchContainer = styled(Pressable)`
  ${styles.schoolSearchContainer}
`;

const SchoolSearchWrapper = styled(View)`
  ${styles.schoolSearchWrapper};
`;

const SchoolSearchTitle = styled(Text)`
  ${styles.schoolSearchTitle}
`;

const Overlay = styled(Pressable)`
  ${styles.overlay}
`;

const ShowMoreContainer = styled(View)`
  ${styles.showMoreContainer}
`;

const ShowMoreSection = styled(View)<{ center: boolean }>`
  ${styles.showMoreSection}
`;

const ViewAllSchoolsContainer = styled(View)`
  ${styles.viewAllSchoolsContainer}
`;

const ViewAllSchoolsWrapper = styled(View)`
  ${styles.viewAllSchoolsWrapper}
`;

const AdWrapper = styled(View)`
  ${styles.adWrapper}
`;

interface SchoolDetailViewProps {
  favIDsToSchoolName: { [key: string]: string };
  onToggleFavorite: (id: string, schoolName: string) => void;
  recentIDsToVisitDate: { [key: string]: Date };
  hasFilteredSchool?: boolean;
  onItemClick?: (id: string) => void;
  filterSchools?: School[];
  filterResultHuddleIDs: string[];
  onChange: (value: string) => void;
  query: string;
  onClearInput?: () => void;
  eventSeasons: EventSeason[] | [];
  schoolItem: School;
  isFavorite: boolean;
  isRefreshingSchoolEvents: boolean;
  isLoading: boolean;
  isLoadingShowMore: boolean;
  isMobile: boolean;
  toggleFavorite: () => void;
  resetFilters: () => void;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  refreshEvents: (method: string) => void;
  appliedFilter: SelectedFilter;
  hasLocationFilter: boolean;
  displaySchoolSearch: boolean;
  loadingCategories: boolean;
  useSearchDataHook: (query: string) => SchoolsResponse;
  showMore: () => void;
  totalItems: number;
  totalRemainingItems: number;
  listCategories: CategoriesType;
  todayEvents: EventSeasonCombined[];
  upComingEvents: EventSeasonCombined[];
  sponsor?: IEventSponsorship;
}

export const SchoolDetailView = React.memo(
  ({
    applyFilter,
    displaySchoolSearch,
    eventSeasons,
    favIDsToSchoolName,
    filterResultHuddleIDs,
    filterSchools,
    hasFilteredSchool,
    hasLocationFilter,
    isFavorite,
    isRefreshingSchoolEvents,
    isLoading,
    isLoadingShowMore,
    isMobile,
    listCategories,
    loadingCategories,
    onChange,
    onClearInput,
    onItemClick,
    onToggleFavorite,
    query,
    recentIDsToVisitDate,
    refreshEvents,
    resetFilters,
    schoolItem,
    appliedFilter,
    showMore,
    todayEvents,
    toggleFavorite,
    totalItems,
    totalRemainingItems,
    upComingEvents,
    sponsor
  }: SchoolDetailViewProps) => {
    const { name, facebookUrl, twitterUrl, streetAddress, logoUrl, city, state, zipCode, customPageName } = schoolItem;
    const { primaryColor } = getTicketBackgroundTextColors(schoolItem.primaryColor);
    const headerLogoUrl = logoUrl ?? undefined;
    const footerLocation = `${city ? `${city}, ` : ''}${state ? `${state} ` : ''}${zipCode ? `${zipCode}` : ''}`;
    const showFiltersList = !isEmpty(eventSeasons) || !loadingCategories || !isEmpty(listCategories);
    const { toggleSearchFocused, searchFocused, setActive, focusSearchInput, active } = useSearchPageContext();
    const handlePressOutSide = () => {
      if (searchFocused && Platform.OS !== 'web') {
        toggleSearchFocused && toggleSearchFocused(false);
        setActive(false);
        focusSearchInput(false);
      }
    };
    const handleRefreshEvents = () => {
      refreshEvents(REFRESH_METHOD.REFRESH);
    };
    const { refreshing: isPullRefreshing } = usePullRefresh(handleRefreshEvents, isRefreshingSchoolEvents);
    const { scrollToTop } = useScrollView();
    const shouldDisplayShowMoreBtn = totalRemainingItems > 0;
    const hasGotoTopBtn = !isEmpty(todayEvents) || !isEmpty(upComingEvents);
    const recentSchoolIDs = (filterResultHuddleIDs || []).filter(huddleID => !!recentIDsToVisitDate[huddleID]);
    const { openDrawer } = useDrawerContext();
    const launchDarklyContext = useLaunchDarklyContext();
    const { variation: launchDarklySchoolDistrictEnabled } = launchDarklyContext.features['view-schools-from-district'];
    const { variation: adsEnabled = false } = launchDarklyContext?.features['ads-enabled'] || {};

    const isSchoolDistrict =
      schoolItem.gofanSchoolType === 'School District' && (launchDarklySchoolDistrictEnabled as boolean);
    const districtSchools =
      (useGetAllSchoolsForSchoolDistrict(schoolItem.huddleId, isSchoolDistrict) as SchoolDistrictInfo[]) ?? [];
    const showDistrictButton = isSchoolDistrict && districtSchools.length > 0;

    // code for demo purposes, to be removed later
    const { variation: launchDarklySchoolContextEnabled } = launchDarklyContext.features['school-context-experiment'];
    const isSchoolContext = launchDarklySchoolContextEnabled as boolean;
    useLaunchDarklyContextSync([
      {
        kind: 'school',
        key: schoolItem.huddleId,
        attributes: { schoolId: schoolItem.huddleId }
      }
    ]);
    const filterSettings = {
      events: eventSeasons,
      applyFilter: applyFilter,
      appliedFilter: appliedFilter,
      primaryColor: primaryColor,
      listCategories: listCategories,
      loadingCategories: loadingCategories,
      resetFilters: resetFilters,
      hasLocationFilter: hasLocationFilter
    };

    if (isLoading) {
      return (
        <Container>
          {isPullRefreshing && <PullDownLoadingIcon />}
          <SchoolHeader
            logo={headerLogoUrl}
            primaryColor={primaryColor}
            schoolName={name}
            isFavorite={isFavorite}
            toggleFavorite={toggleFavorite}
            customPageName={customPageName}
            showDistrictButton={showDistrictButton}
            schoolId={schoolItem.huddleId}
          />
          <View>
            <CategoriesLoadingSkeleton isMobile={isMobile} />
            <EventListLoadingSkeleton isMobile={isMobile} />
          </View>
        </Container>
      );
    }
    return (
      <ScrollView
        nestedScrollEnabled={true}
        contentContainerStyle={{
          flexGrow: 1,
          flexDirection: 'column',
          minHeight: '100%'
        }}
      >
        <DrawerContent
          drawerId={ATHLETIC_TEAMS_LIST}
          content={
            <AthleticTeamsListContainer
              schoolName={schoolItem.name}
              schoolId={schoolItem.huddleId}
              filterSettings={filterSettings}
            />
          }
          position={'right'}
          drawerSize={425}
          isFullScreen={isMobile}
        />
        {districtSchools.length > 0 && (
          <DrawerContent
            key={SCHOOL_DISTRICT_LIST}
            drawerId={SCHOOL_DISTRICT_LIST}
            content={
              <SchoolDistrictList
                districtSchools={districtSchools}
                schoolName={schoolItem.customPageName ?? schoolItem.name}
                schoolId={schoolItem.huddleId}
              />
            }
            position={'right'}
            drawerSize={360}
          />
        )}
        {isPullRefreshing && <PullDownLoadingIcon />}
        <Container loading={isLoading}>
          <SchoolHeader
            logo={headerLogoUrl}
            primaryColor={primaryColor}
            schoolName={name}
            isFavorite={isFavorite}
            toggleFavorite={toggleFavorite}
            customPageName={customPageName}
            showDistrictButton={showDistrictButton}
            schoolId={schoolItem.huddleId}
          />
          {isMobile && showDistrictButton && (
            <ViewAllSchoolsContainer>
              <StyledButton
                width='100%'
                onPress={() => openDrawer(SCHOOL_DISTRICT_LIST)}
                accessibilityLabel='View all schools'
              >
                <ViewAllSchoolsWrapper>
                  <StyledText color='white'>View all schools</StyledText>
                  <ArrowRight fill={'white'} height={16} width={16} />
                </ViewAllSchoolsWrapper>
              </StyledButton>
            </ViewAllSchoolsContainer>
          )}

          <RenderFilters isLoading={isLoading} filterSettings={filterSettings} />

          {!isLoading && (
            <>
              {(displaySchoolSearch || hasFilteredSchool) && showFiltersList && (
                <>
                  {active && !hasFilteredSchool && (query || !isEmpty(recentSchoolIDs)) && (
                    <Overlay onPress={handlePressOutSide} activeOpacity={1} />
                  )}
                  <SchoolSearchContainer>
                    <SchoolSearchWrapper
                      displaySearch={displaySchoolSearch || hasFilteredSchool}
                      isMobile={isMobile}
                      {...testProperties(schoolPage.filterSchool)}
                    >
                      <SchoolSearchTitle>Filter by school</SchoolSearchTitle>
                      <SchoolSearch
                        favIDsToSchoolName={favIDsToSchoolName}
                        huddleIDs={filterResultHuddleIDs}
                        onChange={onChange}
                        onClearInput={onClearInput}
                        onItemClick={onItemClick}
                        onToggleFavorite={onToggleFavorite}
                        query={query}
                        placeholder={'Search by school, mascot, city'}
                        filterSchools={filterSchools}
                        applyFilter={applyFilter}
                        recentIDsToVisitDate={recentIDsToVisitDate}
                        hasFilteredSchool={hasFilteredSchool}
                        displaySchoolSearch={displaySchoolSearch}
                      />
                    </SchoolSearchWrapper>
                  </SchoolSearchContainer>
                </>
              )}
              <EventList
                displaySchoolSearch={displaySchoolSearch}
                eventLoading={isLoading}
                isMobile={isMobile}
                refreshEvents={() => refreshEvents(REFRESH_METHOD.REFRESH)}
                applyFilter={applyFilter}
                resetFilters={resetFilters}
                schoolDetail={schoolItem}
                appliedFilter={appliedFilter}
                todayEvents={todayEvents}
                totalItems={totalItems}
                upComingEvents={upComingEvents}
                sponsor={sponsor}
              />
            </>
          )}
          {!isLoading && (hasGotoTopBtn || shouldDisplayShowMoreBtn) && (
            <ShowMoreSection center={!shouldDisplayShowMoreBtn}>
              {shouldDisplayShowMoreBtn && (
                <>
                  <StyledButton
                    disabled={isLoadingShowMore}
                    height='32px'
                    width='150px'
                    padding='0'
                    onPress={showMore}
                    accessibilityLabel={`Show ${
                      totalRemainingItems > DEFAULT_SHOW_MORE_SIZE ? DEFAULT_SHOW_MORE_SIZE : totalRemainingItems
                    }`}
                  >
                    <ShowMoreContainer>
                      {isLoadingShowMore && (
                        <LoadingSpinner spinnerSize={14} asSpinner usingLoadingIndicator></LoadingSpinner>
                      )}
                      <StyledText color='#ffffff'>
                        Show{' '}
                        {totalRemainingItems > DEFAULT_SHOW_MORE_SIZE ? DEFAULT_SHOW_MORE_SIZE : totalRemainingItems}{' '}
                        more
                      </StyledText>
                    </ShowMoreContainer>
                  </StyledButton>
                </>
              )}
              {hasGotoTopBtn && (
                <StyledButton
                  buttonType={'ghost--border'}
                  height='32px'
                  width='137px'
                  padding='0'
                  onPress={scrollToTop}
                  title='Go to top'
                  accessibilityLabel='Go to top'
                />
              )}
            </ShowMoreSection>
          )}
        </Container>
        {!isLoading && (
          <FooterContainer loading={isLoading}>
            <SupportLinksWrapper isMobile={isMobile}>
              <StyledText typeToken='body01' color='textPrimary'>
                Don&apos;t see the event you&apos;re looking for?
              </StyledText>
              <StyledText>
                <TouchableOpacity
                  onPress={() => refreshEvents(REFRESH_METHOD.NEW_EVENTS)}
                  {...testProperties(schoolPage.checkForNewEvent)}
                >
                  <StyledText typeToken='body01' color='linkPrimary'>
                    &nbsp;Check for new events
                  </StyledText>
                </TouchableOpacity>
                <ExternalLink href='http://fansupport.gofan.co/' {...testProperties(schoolPage.visitSupport)}>
                  <StyledText typeToken='body01' color='linkPrimary'>
                    <StyledText typeToken='body01' color='textPrimary'>
                      &nbsp;or
                    </StyledText>
                    &nbsp;visit our support page
                  </StyledText>
                </ExternalLink>
              </StyledText>
              {totalItems < 1 && adsEnabled && (
                <AdWrapper>
                  <AdBanner
                    page={AdPageType.EVENT_LIST}
                    sectionCustomTargetingParameters={{
                      'school-id': schoolItem.huddleId,
                      'school-state': schoolItem.state
                    }}
                  />
                </AdWrapper>
              )}
              {/* { code for demo purposes, to be removed later } */}
              {isSchoolContext ? <ExampleSchoolContextualLDComponent /> : null}
            </SupportLinksWrapper>
            <SchoolFooter
              schoolName={customPageName || name}
              address={streetAddress}
              location={footerLocation}
              facebookUrl={facebookUrl}
              twitterUrl={twitterUrl}
            />
          </FooterContainer>
        )}
      </ScrollView>
    );
  }
);
SchoolDetailView.displayName = 'SchoolDetailView';
