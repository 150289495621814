import React from 'react';
import TeamListItemFavorite from './TeamListItemFavorite/TeamListItemFavorite';
import TeamListItemRegular from './TeamListItemRegular/TeamListItemRegular';
import { SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
export interface TeamListItemProps {
  gender: string;
  levelName: string;
  seasonName: string;
  isFavorite: boolean;
  toggleFavorite: () => void;
  activityName: string;
  activityIconName?: string;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  resetFilters: (value: string) => void;
  appliedFilter: SelectedFilter;
}

export const TeamListItem = (props: TeamListItemProps) => {
  if (props.isFavorite) {
    return <TeamListItemFavorite {...props} />;
  } else {
    return <TeamListItemRegular {...props} />;
  }
};

export default TeamListItem;
