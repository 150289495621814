import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  iconContainer: css<{ backgroundColor: string; isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
    height: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
    border: none;
    border-radius: ${({ isMobile }) => (isMobile ? '9px' : '12px')};
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ backgroundColor }) =>
      backgroundColor.toLowerCase() === '#ffffff'
        ? `border: 1px solid #000000`
        : `background-color: ${backgroundColor}`};
  `
};
