import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './BuyEventTicketBtn.styles';
import { Text, TouchableOpacity } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { useNavigate, useTracking } from '@gf/cross-platform-lib/hooks';
import { EventSeasonCombined } from '../../../../..';
import { eventCardComponent, getValue, testProperties } from '@gf/cross-platform-lib/utils';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Link } from '@gf/cross-platform-lib/components';

const ButtonSection = styled(TouchableOpacity)`
  ${styles.buttonSection};
`;

const ButtonText = styled(Text)`
  ${styles.buttonText};
`;

export interface BuyEventTicketBtnProps {
  isSeason: boolean;
  isMobilePass: boolean;
  isFundraiser?: boolean;
  link: string;
  event: EventSeasonCombined;
}

export const BuyEventTicketBtn = ({
  isSeason,
  isMobilePass,
  isFundraiser = false,
  link,
  event
}: BuyEventTicketBtnProps) => {
  const { isWebApp } = useMediaQuery();
  let title = 'Buy tickets';
  isSeason && (title = 'Buy season');
  isMobilePass && (title = 'Buy pass');
  isFundraiser && (title = 'Donate');
  const { trackViewEvent } = useTracking();
  const navigate = useNavigate();

  const handleNavViewEvent = () => {
    !isWebApp && navigate(link);
    trackViewEvent(event);
  };

  const renderButton = () => (
    <ButtonSection onPress={() => handleNavViewEvent()} {...testProperties(eventCardComponent.buyProduct)}>
      <ButtonText id={`btnGetTickets-${getValue(event?.id)}`}>{title}</ButtonText>
      <Carbonicons name='arrow-right' size={16} color='#ffffff' />
    </ButtonSection>
  );

  return isWebApp ? (
    <Link title={title} href={link}>
      {renderButton()}
    </Link>
  ) : (
    renderButton()
  );
};
