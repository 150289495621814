import isArray from 'lodash/isArray';
import get from 'lodash/get';

/**
 * @param {any} data - data
 * @param {string=} key - a key of object
 * @param {string=} [defaultValue=''] - default value, it will return if the data can not get value
 * @returns {any} This is the result
 * @description This is the function to:
 * get value from object, if value is undefined or null, return default value
 * get default value if the data is undefined or null
 * @example
 * var x = getValue('foo'); // 'foo'
 * var x = getValue(undefined); // ''
 * var x = getValue(undefined, undefined, 'default value'); // 'default value'
 * var x = getValue({foo: 1}, 'foo'); // 1
 * var x = getValue({foo: 1}, 'bar'); // ''
 * var x = getValue({foo: 1}, 'bar', 'empty'); // 'empty'
 */
export const getValue = (data: any | undefined, key: any | undefined = undefined, defaultValue: any = '') => {
  if (typeof data === 'object' && !isArray(data)) {
    return get(data, key) || defaultValue;
  }
  return data || defaultValue;
};
