import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './MobilePassFooter.styles';
import { EventFooterItem } from '../EventFooterItem';
import { View } from 'react-native';
import { eventCardComponent, testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const TagWrapper = styled(View)`
  ${styles.tagWrapper};
`;

export interface MobilePassFooterProps {
  isMobile: boolean;
  isPlayoff: boolean;
  isFeatured: boolean;
}

export const MobilePassFooter = ({ isMobile, isPlayoff, isFeatured }: MobilePassFooterProps) => (
  <Wrapper {...testProperties(eventCardComponent.secondarySection)} isMobile={isMobile}>
    <TagWrapper isMobile={isMobile}>
      {isFeatured && <EventFooterItem iconName='featuredStar' title='Featured' />}
      {isPlayoff && <EventFooterItem iconName='playoffIcon' title='Playoff' />}
    </TagWrapper>
  </Wrapper>
);
