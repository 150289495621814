import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LocationObject } from 'expo-location';
import { SchoolDetailView } from '../views';
import { School } from '@gf/cross-platform-lib/interfaces';
import { useParams, useRouter, useTracking } from '@gf/cross-platform-lib/hooks';
import {
  FilterType,
  FilterURLType,
  getQueryStringFilter,
  mapToQueryStringFilter,
  SelectedFilter
} from '@gf/cross-platform-lib/utils/filterUtils';
import { DefaultSchoolsResponse, Fan, SchoolsResponse } from '@gf/cross-platform-lib/models';
import {
  DEFAULT_PAGING_SIZE,
  FILTER_ACTIVITY,
  FILTER_GENDER,
  FILTER_HOMEAWAY,
  FILTER_LEVEL,
  FILTER_PLAYOFFS,
  FILTER_SCHOOLS,
  FILTER_OTHER,
  FILTER_BATCH
} from '@gf/cross-platform-lib/constants';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';
import uniq from 'lodash/uniq';
import {
  checkEmptyValidFilters,
  GetCurrentApplicationConfiguration,
  getValidFilterPairs,
  isAggregatorSchool,
  shouldShowLocationFilter
} from '@gf/cross-platform-lib/utils';
import { useLocation, useSearchPageContext } from '@gf/cross-platform-lib/providers';
import haversine, { Coordinate } from 'haversine';
import { useGetAllEventCategories, useSearchSchoolEvents } from '../hooks';
import {
  useFetchEventSponsorQuery,
  useFetchSchool,
  useFetchSchools
} from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { getEventListPageViewProps } from '../../../hooks/tracking/utils';
import { Params } from '../SchoolScreen';

function doesExist<T>(argument: T | undefined | null): argument is T {
  return argument !== (undefined || null);
}

const initialState = {
  activities: [],
  levels: [],
  genders: [],
  homeAway: [],
  playoffs: false,
  schoolFilter: '',
  dataLoaded: false
};

const appConfig = GetCurrentApplicationConfiguration();

const getDistance = (userLocation: LocationObject, schoolLocation: Coordinate) => {
  return Math.floor(
    haversine(
      {
        latitude: userLocation?.coords.latitude,
        longitude: userLocation?.coords.longitude
      },
      schoolLocation,
      { unit: 'mile' }
    )
  );
};

const otherActivitiesNames = ['Parking', 'Digital Season Pass', 'Fundraiser'];
export const filterOutOtherActivities = (activities: string[]): string[] =>
  activities.filter(activity => otherActivitiesNames.includes(activity));

export const filterOnlyOtherActivities = (activities: string[]): string[] =>
  activities.filter(activity => !otherActivitiesNames.includes(activity));

const orderSchoolsByLocation = (userLocation: LocationObject, schools: School[]) => {
  return schools
    .map(school => {
      if (school?.latitude && school?.longitude) {
        const schoolLocation = {
          latitude: school?.latitude || 0,
          longitude: school?.longitude || 0
        };
        const distance = getDistance(userLocation, schoolLocation);
        return { ...school, distance: distance || 0 };
      }
      return { ...school, distance: 0 };
    })
    .sort((a, b) => a.distance - b.distance);
};

export interface SchoolDetailProps {
  id: string;
  isMobile: boolean;
  replaceURLQueryString: (queryString: string) => void;
  setPageTitle: (pageTitle: string) => void;
  filterQuery: FilterURLType;
  toggleFooterVisibility: (shouldHide: boolean) => void;
}

export const SchoolContainer = (props: SchoolDetailProps) => {
  const { data, isLoading } = useFetchSchool(props.id, { gofanPageEnabled: true }, true);
  const school = data?.data ? data?.data : null;
  useEffect(() => {
    props.setPageTitle(`${school?.name || ''} Events and Tickets by GoFan`);
  }, [school]);

  return school ? <SchoolDetailContainer {...props} school={school} schoolLoading={isLoading} /> : null;
};

export const SchoolDetailContainer = ({
  school,
  filterQuery,
  replaceURLQueryString,
  isMobile,
  setPageTitle,
  toggleFooterVisibility,
  schoolLoading
}: SchoolDetailProps & { school: School; schoolLoading: boolean }) => {
  const schoolId = school?.huddleId;

  const {
    items: eventSeasons,
    totalItems,
    totalRemainingItems,
    isLoading: loading,
    isFetching: isRefreshingSchoolEvents,
    isFetchingNextPage: showMoreLoading,
    refetch,
    applyFilter,
    loadMore: showMore,
    todayEvents,
    upcomingEvents
  } = useSearchSchoolEvents({ schoolId }, { page: 1, size: DEFAULT_PAGING_SIZE });

  const { setActive } = useSearchPageContext();
  const refechingDataRef = React.useRef(false);
  const params = useParams() as Params;
  const fan = Fan.getInstance();
  const [userInput, setUserInput] = React.useState('');
  const [isApplyFilter, setApplyFilter] = React.useState(false);
  const { location: userLocation, permission: locationPermission } = useLocation();
  const {
    listCategories,
    isLoading: loadingCategories,
    refetch: refetchCategories
  } = useGetAllEventCategories(schoolId);

  const {
    trackUnFavoriteEvent,
    trackFavoriteEvent,
    trackRefreshEventList,
    trackSearchEvent,
    identifyFavoriteSchool,
    trackEventListPageView
  } = useTracking();
  const onClearInput = () => {
    setAppliedFilter(prevState => {
      return { ...prevState, schoolFilter: '' };
    });
    setUserInput('');
    handleApplyFilter(FILTER_SCHOOLS, false, '');
  };
  const onChange = (event: any) => {
    setUserInput(event);
    if (event.length === 0) {
      setAppliedFilter(prevState => {
        return { ...prevState, schoolFilter: '' };
      });
    }
  };

  const [hasFilteredSchool, setHasFilteredSchool] = useState(false);

  const [appliedFilter, setAppliedFilter] = useState<SelectedFilter>(initialState);
  const { data: response } = useFetchSchool(appliedFilter.schoolFilter || '', undefined, true);
  const { data: sponsor } = useFetchEventSponsorQuery({
    eventIds: eventSeasons.map(event => event.id)
  });
  useMemo(() => {
    let newInput = response?.data?.name || '';
    if (userInput !== newInput) {
      setUserInput(newInput);
    }
  }, [response]);

  useEffect(() => {
    if (appliedFilter.schoolFilter) {
      setHasFilteredSchool(true);
    } else {
      setHasFilteredSchool(false);
    }
  }, [appliedFilter.schoolFilter]);

  const initialFavoriteSchool = Object.keys(fan.favIDsToSchoolName).includes(schoolId);
  const [isFavorite, setIsFavorite] = useState(initialFavoriteSchool);

  const preparedRef = useRef(false);
  const router = useRouter();
  const hasLocationFilter =
    !loadingCategories && !isEmpty(listCategories)
      ? listCategories?.searchOptions?.length === 2 && !isAggregatorSchool(school?.gofanSchoolType)
      : shouldShowLocationFilter(school?.gofanSchoolType || '', school?.huddleId || '', eventSeasons);

  const toggleFavorite = async () => {
    setIsFavorite(currentFavorite => !currentFavorite);
    await fan.toggleFavoriteSchool(schoolId, school?.name!);
    isFavorite
      ? trackUnFavoriteEvent(router.currentRoute, {
          orgId: school?.huddleId || '',
          orgType: school?.gofanSchoolType || ''
        })
      : trackFavoriteEvent(router.currentRoute, {
          orgId: school?.huddleId || '',
          orgType: school?.gofanSchoolType || ''
        });
    identifyFavoriteSchool(fan);
  };

  let hasOpponentSchools = false;

  useEffect(() => {
    if (!school) {
      return;
    }
    fan.schoolIDsToVisitDate[school.huddleId] = new Date();
    fan.save();
  }, [school]);

  const handleApplyFilter = (type: string, selected: boolean, params?: any) => {
    setActive(false);
    switch (type) {
      case FILTER_BATCH: {
        setAppliedFilter(() => {
          return {
            ...params,
            dataLoaded: true
          };
        });
        break;
      }
      case FILTER_ACTIVITY: {
        const foundActivity = appliedFilter?.activities.find(i => selected && i === params[0]);
        setAppliedFilter((prev: FilterType) => {
          const cloneActivities = [...prev.activities];
          return {
            ...prev,
            activities: foundActivity ? pull(cloneActivities, ...params) : [...cloneActivities, ...params],
            dataLoaded: true
          };
        });
        break;
      }
      case FILTER_LEVEL: {
        const foundLevel = appliedFilter?.levels.find(i => selected && i === params[0]);
        setAppliedFilter((prev: FilterType) => {
          const cloneLevels = [...prev.levels];
          return {
            ...prev,
            levels: foundLevel ? pull(cloneLevels, ...params) : [...cloneLevels, ...params],
            dataLoaded: true
          };
        });
        break;
      }
      case FILTER_GENDER: {
        const foundGenders = appliedFilter?.genders.find(i => selected && i === params[0]);
        setAppliedFilter((prev: FilterType) => {
          const cloneGenders = [...prev.genders];
          return {
            ...prev,
            genders: foundGenders ? pull(cloneGenders, ...params) : [...cloneGenders, ...params],
            dataLoaded: true
          };
        });
        break;
      }
      case FILTER_HOMEAWAY: {
        const foundHomeAway = (appliedFilter?.homeAway || []).find(i => selected && i === params[0]);
        setAppliedFilter((prev: FilterType) => {
          const cloneHomeAway = [...prev.homeAway];
          return {
            ...prev,
            homeAway: foundHomeAway ? pull(cloneHomeAway, ...params) : [...cloneHomeAway, ...params],
            dataLoaded: true
          };
        });
        break;
      }
      case FILTER_PLAYOFFS: {
        setAppliedFilter((prev: FilterType) => {
          return {
            ...prev,
            playoffs: !prev.playoffs,
            dataLoaded: true
          };
        });
        break;
      }
      case FILTER_SCHOOLS: {
        setAppliedFilter((prev: FilterType) => ({ ...prev, schoolFilter: params, dataLoaded: true }));
        break;
      }
      default:
        break;
    }
    setApplyFilter(true);
  };

  const [uniqueSchools, setUniqueSchools] = useState<string[] | undefined>(undefined);
  if (!uniqueSchools && eventSeasons.length > 0 && !loadingCategories) {
    const schools = !isEmpty(listCategories)
      ? listCategories?.schools
      : eventSeasons.map(event => [
          event.schoolHuddleId ?? '',
          event.opponentSchoolId ?? '',
          ...(event.taggedSchoolHuddleIds ?? [])
        ]);
    const flat = schools.flat();
    const flatfilter = flat.filter(huddleID => huddleID !== school?.huddleId);
    setUniqueSchools(uniq(flatfilter).filter(id => !!id));
  }
  const involvedSchools =
    useFetchSchools(uniqueSchools || [])
      .map(res => res.data?.data)
      .filter(doesExist) || [];

  const useSearchData = (query: string): SchoolsResponse => {
    let schools = involvedSchools;
    if (schools.length > 0 && query.trim()) {
      schools = schools.filter(school => school.name.toUpperCase().includes(query.toUpperCase()));
    }
    const response = DefaultSchoolsResponse();
    response.loading = false;
    response.data =
      locationPermission?.granted && userLocation
        ? [...(orderSchoolsByLocation(userLocation, schools) || [])]
        : schools;
    return response;
  };

  const { data: schools } = useSearchData(userInput);

  const HuddleIDs = schools?.filter(school => school.gofanPageEnabled).map(school => school.huddleId);

  if (
    isAggregatorSchool(school.gofanSchoolType) &&
    school?.gofanPageEnabled &&
    (!isEmpty(HuddleIDs) || !isEmpty(userInput)) &&
    (eventSeasons.some(event => event.opponentSchoolId) || listCategories?.schools.length > 0)
  ) {
    hasOpponentSchools = true;
  }

  const getFavSchoolIds = (
    favIDsToSchoolName: { [key: string]: string },
    recentIDsToVisitDate: { [key: string]: Date }
  ) => {
    const recentFavSchoolIds = Object.keys(favIDsToSchoolName).filter(id =>
      Object.keys(recentIDsToVisitDate).find(recentID => recentID === id)
    );
    const favSchoolIdsOnly = Object.keys(favIDsToSchoolName).filter(id =>
      isEmpty(Object.keys(recentIDsToVisitDate).find(recentID => recentID === id))
    );

    return [...recentFavSchoolIds, ...favSchoolIdsOnly].slice(0, 15);
  };

  const [_results, setResults] = React.useState<School[] | null>([]);
  const [favIDsToSchoolName, setFavIDsToSchoolName] = useState(fan.favIDsToSchoolName);
  const [recentIDsToVisitDate] = useState(fan.schoolIDsToVisitDate);

  let trackingRedirectFromSchoolDistrictRef = useRef(!!params?.redirectFromSchoolDistrict);
  const favSchoolIds = getFavSchoolIds(favIDsToSchoolName, recentIDsToVisitDate);
  const recentSchoolIds = Object.keys(recentIDsToVisitDate)
    .filter(huddleID => !favSchoolIds.includes(huddleID))
    .sort((a, b) => (recentIDsToVisitDate[a] > recentIDsToVisitDate[b] ? -1 : 1))
    .slice(0, 5);
  const favAndRecentResults = useFetchSchools(uniq([...recentSchoolIds, ...favSchoolIds]) || [])
    .map(res => res.data?.data)
    .filter(doesExist);
  const { data: searchResults } = useSearchData(userInput);

  React.useEffect(() => {
    if (!userInput) {
      if (favAndRecentResults) {
        setResults(favAndRecentResults);
      } else {
        setResults([]);
      }
    }
  }, [userInput]);

  React.useEffect(() => {
    const timer = setTimeout(async () => {
      if (userInput && !appliedFilter.schoolFilter) {
        trackSearchEvent(router.currentRoute, userInput);
      }
    }, Number(appConfig.settings.searchInputTimeout));
    return () => {
      clearTimeout(timer);
    };
  }, [userInput]);

  const onToggleFavorite = (huddleID: string, schoolName: string) => {
    setFavIDsToSchoolName(prevState => {
      const newState = { ...prevState };
      if (newState[huddleID]) {
        delete newState[huddleID];
      } else {
        newState[huddleID] = schoolName;
      }
      return newState;
    });
    if (
      (!userInput || isEmpty(searchResults?.find(school => school.huddleId === huddleID))) &&
      isEmpty(Object.keys(recentIDsToVisitDate).find(id => id === huddleID))
    ) {
      setResults(prevState => (prevState || []).filter((school: School) => school.huddleId !== huddleID));
    }
  };

  const updateQueryString = (filterState: SelectedFilter) => {
    const query = getQueryStringFilter(mapToQueryStringFilter(filterState));
    replaceURLQueryString(query);

    const updatedFilterState = {
      ...filterState,
      levels: filterState.levels.map(level => ({ levelName: level })),
      activitiesName: filterState.activities,
      searchOption: filterState.homeAway.length > 0 ? filterState.homeAway[0] : ''
    };

    const mapToFilterParams = (state: typeof updatedFilterState) => ({
      schoolId,
      activitiesName: !isEmpty(state.activitiesName) ? state.activitiesName : undefined,
      levels: !isEmpty(state.levels) ? state.levels : undefined,
      genders: !isEmpty(state.genders) ? state.genders : undefined,
      postSeason: state.playoffs || undefined,
      searchOption: state.searchOption || undefined,
      selectedSchools: state.schoolFilter ? [state.schoolFilter] : undefined
    });

    applyFilter(mapToFilterParams(updatedFilterState));
  };

  const handleResetFilter = (type?: string) => {
    if (type) {
      setAppliedFilter(prev => {
        const newState = { ...prev, dataLoaded: true };
        switch (type) {
          case FILTER_ACTIVITY:
            newState.activities = filterOutOtherActivities(prev.activities);
            newState.playoffs = false;
            break;
          case FILTER_LEVEL:
            newState.levels = [];
            break;
          case FILTER_GENDER:
            newState.genders = [];
            break;
          case FILTER_HOMEAWAY:
            newState.homeAway = [];
            break;
          case FILTER_OTHER:
            newState.activities = filterOnlyOtherActivities(prev.activities);
            break;
          default:
            setAppliedFilter({
              ...initialState,
              dataLoaded: true
            });
            setUserInput('');
            replaceURLQueryString('');

            applyFilter({ schoolId });
            return initialState;
        }

        updateQueryString(newState);
        return newState;
      });
    } else {
      setAppliedFilter({
        ...initialState,
        dataLoaded: true
      });
      setUserInput('');
      replaceURLQueryString('');
      applyFilter({ schoolId });
    }
  };

  const handleRefreshEvents = (method: string) => {
    refetchCategories();
    refetch();
    refechingDataRef.current = true;
    trackRefreshEventList(method);
  };

  const setSchoolDetailPageTitle = () => {
    setPageTitle(`${school?.name || ''} Events and Tickets by GoFan`);
  };

  // Apply filter whenever UI changes
  useEffect(() => {
    if (!isEmpty(appliedFilter) && isApplyFilter && !loadingCategories) {
      const { activities = [], homeAway, levels, genders, playoffs, schoolFilter } = appliedFilter || {};
      const query = getQueryStringFilter(mapToQueryStringFilter(appliedFilter));
      if (!checkEmptyValidFilters(getValidFilterPairs(filterQuery, schoolId || '', listCategories)) && !query) return;
      replaceURLQueryString(query);
      if (query) {
        applyFilter({
          schoolId,
          activitiesName: !isEmpty(activities) ? activities : undefined,
          levels: !isEmpty(levels) ? levels.map(level => ({ levelName: level })) : undefined,
          genders: !isEmpty(genders) ? genders : undefined,
          postSeason: playoffs ? Boolean(playoffs) : undefined,
          searchOption: !isEmpty(homeAway) ? (homeAway.length > 1 ? 'all' : homeAway.join('')) : undefined,
          selectedSchools: schoolFilter ? [schoolFilter] : undefined
        });
      } else {
        applyFilter({ schoolId });
      }
      setApplyFilter(false);
    }
  }, [appliedFilter, loadingCategories]);

  //Mapping URL params to UI filter
  useEffect(() => {
    if (!preparedRef.current && !loading && !loadingCategories) {
      preparedRef.current = true;
      const urlParams = getValidFilterPairs(filterQuery, schoolId, listCategories);
      setAppliedFilter(prevState => ({
        ...prevState,
        activities: urlParams.activities,
        levels: urlParams.levels.map(level => level.levelName),
        genders: urlParams.gendersValue,
        homeAway: urlParams.homeAway,
        playoffs: Boolean(urlParams.playoffsValue),
        schoolFilter: urlParams.schoolFilterValue,
        dataLoaded: true
      }));
      if (!urlParams.isEmptyFilterParams) {
        setApplyFilter(true);
      }
    }
  }, [preparedRef.current, loading, loadingCategories]);

  useEffect(() => {
    setSchoolDetailPageTitle();
  }, []);

  useEffect(() => {
    toggleFooterVisibility(loading || !preparedRef.current || isApplyFilter);
  }, [loading, isApplyFilter, toggleFooterVisibility]);

  useEffect(() => {
    if (trackingRedirectFromSchoolDistrictRef.current && preparedRef.current && !loading && !schoolLoading) {
      trackingRedirectFromSchoolDistrictRef.current = false;
      trackEventListPageView(getEventListPageViewProps({ id: school.huddleId }));
    }
  }, [trackingRedirectFromSchoolDistrictRef, loading, schoolLoading, preparedRef.current]);
  return (
    <>
      {school ? (
        <SchoolDetailView
          applyFilter={handleApplyFilter}
          displaySchoolSearch={hasOpponentSchools}
          eventSeasons={eventSeasons}
          favIDsToSchoolName={favIDsToSchoolName}
          filterResultHuddleIDs={HuddleIDs || []}
          filterSchools={schools ? schools : []}
          hasFilteredSchool={hasFilteredSchool}
          hasLocationFilter={hasLocationFilter}
          isFavorite={isFavorite}
          isLoading={loading || !preparedRef.current || isApplyFilter}
          isRefreshingSchoolEvents={isRefreshingSchoolEvents}
          isLoadingShowMore={showMoreLoading}
          isMobile={isMobile}
          onChange={onChange}
          onClearInput={onClearInput}
          onToggleFavorite={onToggleFavorite}
          query={userInput}
          recentIDsToVisitDate={recentIDsToVisitDate}
          refreshEvents={handleRefreshEvents}
          resetFilters={handleResetFilter}
          schoolItem={school}
          appliedFilter={appliedFilter}
          showMore={showMore}
          toggleFavorite={toggleFavorite}
          totalItems={totalItems}
          totalRemainingItems={totalRemainingItems}
          useSearchDataHook={useSearchData}
          listCategories={listCategories}
          loadingCategories={loadingCategories}
          todayEvents={todayEvents}
          upComingEvents={upcomingEvents}
          sponsor={sponsor?.data}
        />
      ) : null}
    </>
  );
};
