import { School } from '@gf/cross-platform-lib/interfaces';
import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { InvolvedSchoolTag } from '../InvolvedSchoolTag';
import { styles } from './EventInvolvedSchools.styles';
import { Text, View, ScrollView } from 'react-native';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const SchoolTitle = styled(Text)`
  ${styles.schoolTitle};
`;

const InvolvedSchoolTags = styled(View)`
  ${styles.involvedSchoolTags};
`;

const ScrollViewWrapper = styled(ScrollView)`
  ${styles.scrollViewWrapper};
`;

export interface EventInvolvedSchoolsProps {
  involvedSchools: School[];
  isMobile: boolean;
  homeSchoolId: string;
}

export function EventInvolvedSchools({ involvedSchools, isMobile, homeSchoolId }: EventInvolvedSchoolsProps) {
  const renderInvolvedSchoolTags = () => (
    <InvolvedSchoolTags isMobile={isMobile}>
      {involvedSchools.map((school, index) => (
        <InvolvedSchoolTag
          key={index}
          title={school.name}
          showHomeIcon={school.huddleId === homeSchoolId}
          isMobile={isMobile}
        />
      ))}
    </InvolvedSchoolTags>
  );
  return (
    <Wrapper isMobile={isMobile}>
      <SchoolTitle isMobile={isMobile}>{`Schools (${involvedSchools.length})${isMobile ? '' : ':'}`}</SchoolTitle>
      {isMobile ? (
        <ScrollViewWrapper showsVerticalScrollIndicator={false} horizontal={isMobile} isMobile={isMobile}>
          {renderInvolvedSchoolTags()}
        </ScrollViewWrapper>
      ) : (
        <>{renderInvolvedSchoolTags()}</>
      )}
    </Wrapper>
  );
}
