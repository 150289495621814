import { SchoolInterface } from '@gf/cross-platform-lib/interfaces';

/** @deprecated */
export interface SchoolModel {
  id: string;
  name: string;
  secondaryColor: string;
  logoUrl: string;
  primaryColor: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  facebookUrl: string;
  twitterUrl: string;
  searchEnabled: boolean;
  gofanPageEnabled: boolean;
  industryCode: string;
  events?: any[];
  taggedSchools?: any[];
  huddleId: string;
}

/** @deprecated */
export type School = SchoolInterface;

export interface SchoolsResponse {
  data: SchoolInterface[] | null;
  loading: boolean;
  error: {
    message: string;
    httpStatus: { code: number; message: string } | null;
  } | null;
  refetch: () => void;
  remove: () => void;
  save: () => void;
}

export function DefaultSchoolsResponse(): SchoolsResponse {
  return {
    loading: true,
    data: null,
    error: null,
    refetch: () => {},
    remove: () => {},
    save: () => {}
  };
}
