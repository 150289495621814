import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    width: 100%;
    flex-direction: row;
    padding: 8px;
    padding-right: 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  `,
  leftContainer: css`
    flex-direction: row;
    align-items: center;
  `,
  rightContainer: css`
    flex-direction: row;
    align-items: center;
    margin-right: -8px; // Pulls chevron to line up with header close button
  `,
  teamName: css`
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 18px;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
  `,
  seasonName: css`
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 18px;
    margin-right: 8px;
  `
};
