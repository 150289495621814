import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css<{ isMobile?: boolean }>`
    flex-grow: 1;
    margin-bottom: 8px;
    margin-right: 24px;
    max-width: 100%;
    ${({ isMobile }) =>
      isMobile
        ? ``
        : `
        flex: 1;
    `};
  `,
  title: css<{ isMobile: boolean }>`
    color: #323232;
    font-family: Rubik-SemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    ${({ isMobile }) =>
      isMobile &&
      `
        font-size: 24px;
        line-height: 30px;
    `};
  `,
  subTitle: css`
    flex-direction: row;
    align-items: center;
    width: 100%;
  `,
  eventInfo: css<{ isMobile: boolean; eventCount: number }>`
    height: 18px;
    align-items: center;
    justify-content: center;
    width: ${({ eventCount }) => (eventCount < 10 ? `136px` : `142px`)};
    background-color: rgb(50, 50, 50);
    border-radius: 10px;
    margin-left: ${({ isMobile }) => (isMobile ? 0 : `16px`)};
    margin-vertical: ${({ isMobile }) => (isMobile ? '4px' : 0)};
  `,
  additionalInfoWrapper: css`
    max-height: 100%;
  `,
  progressWrapper: css<{ isWeb: boolean }>`
    padding-top: 8px;
    padding-bottom: 8px;
  `
};
