import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './SchoolDetailView.styles';
import { CategoryFilters } from '@gf/cross-platform-lib/modules/school/components';
import { EventSeason, CategoriesType } from '@gf/cross-platform-lib/interfaces';
import { SelectedFilter } from '@gf/cross-platform-lib/utils/filterUtils';
import { CategoryFiltersLegacy } from '@gf/cross-platform-lib/modules/school/components/CategoryFilters/Legacy/CategoryFiltersLegacy.web';
import { HomeAwayFilterLegacy } from '../../components/HomeAwayFilter/Legacy/HomeAwayFilterLegacy';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';

interface FilterSettings {
  events: EventSeason[];
  applyFilter: (type: string, selected: boolean, params: any) => void;
  resetFilters: () => void;
  appliedFilter: SelectedFilter;
  listCategories: CategoriesType;
  loadingCategories: boolean;
  hasLocationFilter: boolean;
  primaryColor: string;
}

interface RenderFiltersProps {
  isLoading: boolean;
  filterSettings: FilterSettings;
}

const FiltersContainer = styled(View)`
  ${styles.filtersContainer}
`;

const FiltersText = styled(Text)`
  ${styles.filtersText}
`;

const Border = styled(View)`
  ${styles.border}
`;

const isSelectedFilterEmpty = (filter: SelectedFilter): boolean => {
  const { dataLoaded: _dataLoaded, ...rest } = filter; // Exclude dataLoaded
  return !Object.values(rest).some(value => {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === 'boolean') {
      return value;
    } else {
      return value !== '';
    }
  });
};

const RenderFilters: React.FC<RenderFiltersProps> = ({ isLoading, filterSettings }) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: teamsFlagEnabled } = launchDarklyContext.features['teams-in-gofan'];

  const filtersSelected = !isSelectedFilterEmpty(filterSettings.appliedFilter);

  // if there are any events, or if any filters have been selected, show the filter group
  const showFiltersText = filterSettings.events.length > 0 || filtersSelected;

  if (!isLoading && showFiltersText) {
    return teamsFlagEnabled ? (
      <FiltersContainer>
        <FiltersText>Filters</FiltersText>
        <Border></Border>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          <CategoryFilters {...filterSettings} />
        </ScrollView>
      </FiltersContainer>
    ) : (
      <>
        <CategoryFiltersLegacy {...filterSettings} />
        {filterSettings.hasLocationFilter && <HomeAwayFilterLegacy {...filterSettings} />}
      </>
    );
  }

  return null;
};

export default RenderFilters;
