import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './Tag.styles';
import { GFImage } from '@gf/cross-platform-lib/components';
import { Platform, TouchableOpacity, View } from 'react-native';

const expandIcon = 'chevron-down.png';

const Container = styled(View)`
  ${styles.container};
`;

const Icon = styled(View)`
  ${styles.icon};
`;

const ExpandIcon = styled(View)`
  ${styles.expandIcon};
`;

const LabelContainer = styled.Text`
  ${styles.labelContainer}
`;

export interface TagProps {
  label: string;
  renderIcon?: string;
  selected: boolean;
  expand?: boolean;
  disabledHover?: boolean;
  onClick?: () => void;
  iconStyle?: object;
  shouldDisplayBorder?: boolean;
  primaryColor?: string;
}

export const Tag = ({
  label = '',
  renderIcon,
  selected,
  expand,
  disabledHover = false,
  onClick,
  iconStyle,
  shouldDisplayBorder = false,
  primaryColor = '000000'
}: TagProps) => {
  const renderGFImage = (tintColor?: string) => (
    <GFImage width={14} height={14} name={renderIcon || ''} tintColor={tintColor} />
  );

  return (
    <TouchableOpacity onPress={onClick}>
      <Container
        selected={selected}
        disabledHover={disabledHover}
        shouldDisplayBorder={shouldDisplayBorder}
        primaryColor={primaryColor}
      >
        {renderIcon && (
          <Icon selected={Platform.OS === 'web' && selected} style={iconStyle}>
            {Platform.OS === 'web' ? renderGFImage() : renderGFImage(selected ? 'white' : 'black')}
          </Icon>
        )}
        <LabelContainer selected={selected}>{label}</LabelContainer>
        {expand && (
          <ExpandIcon>
            <GFImage height={5.25} width={8.75} name={expandIcon} />
          </ExpandIcon>
        )}
      </Container>
    </TouchableOpacity>
  );
};
